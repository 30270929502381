<template>
  <div>
    <header-formulir
      :date="$date()"
      :title="'Formulir Limbah Fasyankes'"
    />
    <div class="content-layout-formulir">
      <!-- <v-container> -->
      <!-- Tab2 -->
      <div
        v-if="isiFormulir && tab == 2"
        class=""
      >
        <!-- <div class="side_toolbar">
            <div>
              <div class="item_toolbar" @click="setupForm(true)">
                <v-progress-circular
                  v-if="loadTempo"
                  indeterminate
                  color="#00b4cc"
                ></v-progress-circular>
                <v-icon color="#00b4cc" v-else large
                  >mdi-content-save-outline</v-icon
                >
              </div>
            </div>
          </div> -->
        <div class="d-flex justify-center">
          <div style="position: relative; width: 80%">
            <div class="d-flex">
              <v-tabs
                v-model="tabs"
                background-color="transparent"
                class="d-flex fasyankes w-100 m-0"
                hide-slider
              >
                <v-tab
                  disabled
                  href="#tabs1"
                  cols="3"
                  class="text-capitalize mr-3 header_tab"
                  active-class="active_tab"
                >
                  <h3 class="title-tabs">
                    Data Limbah Padat
                  </h3>
                </v-tab>
                <v-tab
                  cols="3"
                  disabled
                  href="#tabs2"
                  class="text-capitalize mr-3 header_tab"
                  active-class="active_tab"
                >
                  <h3 class="title-tabs">
                    Sumber daya dan Organisasi
                  </h3>
                </v-tab>
                <v-tab
                  cols="3"
                  disabled
                  href="#tabs3"
                  class="text-capitalize mr-3 header_tab"
                  active-class="active_tab"
                >
                  <h3 class="title-tabs">
                    Alur Pengelolaan limbah padat
                  </h3>
                </v-tab>
                <v-tab
                  cols="3"
                  disabled
                  href="#tabs4"
                  class="text-capitalize header_tab"
                  active-class="active_tab"
                >
                  <h3 class="title-tabs">
                    Pengolahan Limbah Cair
                  </h3>
                </v-tab>
              </v-tabs>
            </div>
            <v-tabs-items
              v-model="tabs"
              style="background: white"
              class=""
            >
              <!-- Tab 1 -->
              <v-tab-item value="tabs1">
                <v-card
                  v-if="limbah_padat"
                  class="mh-300 custom_card pa-5"
                >
                  <!-- Soal 1 -->
                  <div class="border-soal">
                    <div class="soal-form">
                      <div class="questions">
                        <p>Apakah dilakukan pemilahan limbah domestik, medis tajam, dan medis infeksius pada wadah yang berbeda ?</p>
                      </div>
                      <div class="d-flex align-center">
                        <v-radio-group
                          v-model="
                            limbah_padat.limbah_padat[
                              'apakah_dilakukan_pemilahan_limbah_padat?'
                            ].dataPenyusun_desc
                          "
                          row
                          dense
                          hide-details
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          />
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="0"
                          />
                        </v-radio-group>
                        <div v-if="dataConfirm">
                          <v-btn
                            v-if="
                              dataConfirm.tab1
                                .verification_kode_indikator_waste_data[0]
                                .status == '1'
                            "
                            depressed
                            color="grey lighten-2"
                            dark
                            @click="
                              dataConfirm.tab1.verification_kode_indikator_waste_data[0].status =
                                '0'
                            "
                          >
                            <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                          </v-btn>
                          <v-btn
                            v-if="
                              dataConfirm.tab1
                                .verification_kode_indikator_waste_data[0]
                                .status == '0'
                            "
                            depressed
                            color="primary"
                            @click="
                              dataConfirm.tab1.verification_kode_indikator_waste_data[0].status =
                                '1'
                            "
                          >
                            <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- IF Soal 1 = Yes -->
                  <div
                    v-if="
                      limbah_padat.limbah_padat[
                        'apakah_dilakukan_pemilahan_limbah_padat?'
                      ].dataPenyusun_desc == 1
                    "
                  >
                    <!-- Soal 2 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Limbah Domestik</h6>
                      </div>
                      <div class="d-flex w-100 align-center">
                        <div class="soal-form bors-sol">
                          <div class="questions">
                            <p>Apakah dilakukan pemilahan limbah domestik ?</p>
                          </div>
                          <div class="d-flex align-center">
                            <v-radio-group
                              v-model="
                                limbah_padat.limbah_domestik[
                                  'apakah_dilakukan_pemilahan_domestik'
                                ].dataPenyusun_desc
                              "
                              row
                              dense
                              hide-details
                              class="ma-0"
                            >
                              <v-radio
                                color="#00ccb6"
                                label="Ya"
                                value="1"
                              />
                              <v-radio
                                color="#00ccb6"
                                label="Tidak"
                                value="0"
                              />
                            </v-radio-group>
                          </div>
                        </div>
                        <div v-if="dataConfirm">
                          <v-btn
                            v-if="
                              dataConfirm.tab1
                                .verification_kode_indikator_waste_data[1]
                                .status == '1'
                            "
                            depressed
                            color="grey lighten-2"
                            dark
                            @click="
                              dataConfirm.tab1.verification_kode_indikator_waste_data[1].status =
                                '0'
                            "
                          >
                            <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                          </v-btn>
                          <v-btn
                            v-if="
                              dataConfirm.tab1
                                .verification_kode_indikator_waste_data[1]
                                .status == '0'
                            "
                            depressed
                            color="primary"
                            @click="
                              dataConfirm.tab1.verification_kode_indikator_waste_data[1].status =
                                '1'
                            "
                          >
                            <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                          </v-btn>
                        </div>
                      </div>

                      <div
                        v-if="
                          limbah_padat.limbah_domestik[
                            'apakah_dilakukan_pemilahan_domestik'
                          ].dataPenyusun_desc == 1
                        "
                        class="added-form"
                      >
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-1"
                            for="limbah-organik"
                          >Timbulan limbah organik</label>
                          <div class="input-group">
                            <input
                              id="limbah-organik"
                              v-model="
                                limbah_padat.limbah_domestik['limbah_organik']
                                  .dataPenyusun_desc
                              "
                              type="number"
                              name="limbah-organik"
                              class="form-control form-medium"
                            >
                            <div class="icon-group">
                              kg/bulan
                            </div>
                          </div>
                        </div>
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-1"
                            for="limbah-organik"
                          >Timbulan limbah anorganik</label>
                          <div class="input-group">
                            <input
                              id="limbahAnorganik"
                              v-model="
                                limbah_padat.limbah_domestik[
                                  'limbah_non_organik'
                                ].dataPenyusun_desc
                              "
                              type="number"
                              name="limbahAnorganik"
                              class="form-control form-medium"
                            >
                            <div class="icon-group">
                              kg/bulan
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="
                          limbah_padat.limbah_domestik[
                            'apakah_dilakukan_pemilahan_domestik'
                          ].dataPenyusun_desc == 2
                        "
                      >
                        <div class="added-form">
                          <div class="d-flex align-center per-form-added">
                            <label
                              class="label-tipe-1"
                              for="limbah-organik"
                            >Timbulan total limbah domestik</label>
                            <div class="input-group">
                              <input
                                id="limbah-organik"
                                v-model="
                                  limbah_padat.limbah_domestik[
                                    'jumlah_total_limbah_domestik'
                                  ].dataPenyusun_desc
                                "
                                type="number"
                                name="limbah-organik"
                                class="form-control form-medium"
                              >
                              <div class="icon-group">
                                kg/bulan
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Soal 3 -->
                    <!-- Limbah Medis -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Limbah Medis</h6>
                      </div>
                      <div class="d-flex w-100 align-cente">
                        <div class="soal-form bors-sol">
                          <div class="questions">
                            <p>Apakah dilakukan pemilahan limbah medis ?</p>
                          </div>
                          <v-radio-group
                            v-model="
                              limbah_padat.limbah_medis[
                                'apakah_dilakukan_pemilahan_limbah_medis?'
                              ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                        <div v-if="dataConfirm">
                          <v-btn
                            v-if="
                              dataConfirm.tab1
                                .verification_kode_indikator_waste_data[2]
                                .status == '1'
                            "
                            depressed
                            color="grey lighten-2"
                            dark
                            @click="
                              dataConfirm.tab1.verification_kode_indikator_waste_data[2].status =
                                '0'
                            "
                          >
                            <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                          </v-btn>
                          <v-btn
                            v-if="
                              dataConfirm.tab1
                                .verification_kode_indikator_waste_data[2]
                                .status == '0'
                            "
                            depressed
                            color="primary"
                            @click="
                              dataConfirm.tab1.verification_kode_indikator_waste_data[2].status =
                                '1'
                            "
                          >
                            <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                          </v-btn>
                        </div>
                      </div>

                      <!-- IF Medis YA -->
                      <div
                        v-if="
                          limbah_padat.limbah_medis[
                            'apakah_dilakukan_pemilahan_limbah_medis?'
                          ].dataPenyusun_desc == 1
                        "
                      >
                        <!-- Infeksius -->
                        <div class="added-form mt-4">
                          <div class="border-soal">
                            <div class="title-soal-form bg-next-title">
                              <h6>Limbah Infeksius</h6>
                            </div>
                            <div class="soal-form bors-sol">
                              <div class="questions">
                                <p>Apakah dilakukan pemilahan limbah medis infeksius ?</p>
                              </div>
                              <v-radio-group
                                v-model="
                                  limbah_padat.limbah_infeksius[
                                    'apakah_dilakukan_pemilahan_infeksius'
                                  ].dataPenyusun_desc
                                "
                                row
                                dense
                                hide-details
                                class="ma-0"
                              >
                                <v-radio
                                  color="#00ccb6"
                                  label="Ya"
                                  value="1"
                                />
                                <v-radio
                                  color="#00ccb6"
                                  label="Tidak"
                                  value="2"
                                />
                              </v-radio-group>
                            </div>

                            <!-- IF Infeksius Yes -->
                            <div
                              v-if="
                                limbah_padat.limbah_infeksius[
                                  'apakah_dilakukan_pemilahan_infeksius'
                                ].dataPenyusun_desc == 1
                              "
                              class="added-form"
                            >
                              <div class="d-flex align-center per-form-added">
                                <label
                                  class="label-tipe-1"
                                  for="rataTimbulanLimbahInfeksius"
                                >Timbulan limbah infeksius</label>
                                <div class="input-group">
                                  <input
                                    id="rataTimbulanLimbahInfeksius"
                                    v-model="
                                      limbah_padat.limbah_infeksius[
                                        'limbah_infeksius'
                                      ].dataPenyusun_desc
                                    "
                                    type="number"
                                    name="rataTimbulanLimbahInfeksius"
                                    class="form-control form-medium"
                                  >
                                  <div class="icon-group">
                                    kg/bulan
                                  </div>
                                </div>
                                <span class="span-catatan">Contoh : kasa, kapas, selang infus, selang
                                  kateter, dll</span>
                              </div>
                              <div class="d-flex align-center per-form-added">
                                <label
                                  class="label-tipe-1"
                                  for="rataTimbulanLimbahTajam"
                                >Timbulan limbah tajam</label>
                                <div class="input-group">
                                  <input
                                    id="rataTimbulanLimbahTajam"
                                    v-model="
                                      limbah_padat.limbah_infeksius[
                                        'limbah_tajam'
                                      ].dataPenyusun_desc
                                    "
                                    type="number"
                                    name="rataTimbulanLimbahTajam"
                                    class="form-control form-medium"
                                  >
                                  <div class="icon-group">
                                    kg/bulan
                                  </div>
                                </div>
                                <span class="span-catatan">Contoh : jarum, kaca preparat, dll</span>
                              </div>
                              <div class="d-flex align-center per-form-added">
                                <label
                                  class="label-tipe-1"
                                  for="rataTimbulanLimbahPatologi"
                                >Timbulan limbah patologi</label>
                                <div class="input-group">
                                  <input
                                    id="rataTimbulanLimbahPatologi"
                                    v-model="
                                      limbah_padat.limbah_infeksius[
                                        'limbah_patologi'
                                      ].dataPenyusun_desc
                                    "
                                    type="number"
                                    name="rataTimbulanLimbahPatologi"
                                    class="form-control form-medium"
                                  >
                                  <div class="icon-group">
                                    kg/bulan
                                  </div>
                                </div>
                                <span class="span-catatan">Contoh : organ, potongan tubuh, dll</span>
                              </div>
                            </div>

                            <!-- IF Infeksius Tidak -->
                            <div
                              v-if="
                                limbah_padat.limbah_infeksius[
                                  'apakah_dilakukan_pemilahan_infeksius'
                                ].dataPenyusun_desc == 2
                              "
                              class="added-form"
                            >
                              <div class="d-flex align-center per-form-added">
                                <label
                                  class="label-tipe-1"
                                  for="totalLimbahInfekius"
                                >Timbulan total limbah medis infeksius</label>
                                <div class="input-group">
                                  <input
                                    id="totalLimbahInfekius"
                                    v-model="
                                      limbah_padat.limbah_infeksius[
                                        'jumlah_total_limbah_infeksius'
                                      ].dataPenyusun_desc
                                    "
                                    type="number"
                                    name="totalLimbahInfekius"
                                    class="form-control form-medium"
                                  >
                                  <div class="icon-group">
                                    kg/hari
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- Non Infeksius -->
                        <div class="added-form mt-4">
                          <div class="border-soal">
                            <div class="title-soal-form bg-next-title">
                              <h6>Limbah Non-Infeksius</h6>
                            </div>
                            <div class="soal-form bors-sol">
                              <div class="questions">
                                <p>Apakah dilakukan pemilahan limbah medis non infeksius ?</p>
                              </div>
                              <v-radio-group
                                v-model="
                                  limbah_padat.limbah_non_infeksius[
                                    'apakah_dilakukan_pemilahan_noninfeksius'
                                  ].dataPenyusun_desc
                                "
                                row
                                dense
                                hide-details
                                class="ma-0"
                              >
                                <v-radio
                                  color="#00ccb6"
                                  label="Ya"
                                  value="1"
                                />
                                <v-radio
                                  color="#00ccb6"
                                  label="Tidak"
                                  value="2"
                                />
                              </v-radio-group>
                            </div>

                            <!-- IF Non-Infeksius Yes -->
                            <div
                              v-if="
                                limbah_padat.limbah_non_infeksius[
                                  'apakah_dilakukan_pemilahan_noninfeksius'
                                ].dataPenyusun_desc == 1
                              "
                              class="added-form"
                            >
                              <div class="d-flex align-center per-form-added">
                                <label
                                  class="label-tipe-1"
                                  for="rataTimbulanLimbahFarmasi"
                                >Timbulan limbah farmasi</label>
                                <div class="input-group">
                                  <input
                                    id="rataTimbulanLimbahFarmasi"
                                    v-model="
                                      limbah_padat.limbah_non_infeksius[
                                        'limbah_farmasi'
                                      ].dataPenyusun_desc
                                    "
                                    type="number"
                                    name="rataTimbulanLimbahFarmasi"
                                    class="form-control form-medium"
                                  >
                                  <div class="icon-group">
                                    kg/bulan
                                  </div>
                                </div>
                                <span class="span-catatan">Contoh : Obat kadaluarsa, dll</span>
                              </div>
                              <div class="d-flex align-center per-form-added">
                                <label
                                  class="label-tipe-1"
                                  for="rataTimbulanLimbahKimia"
                                >Timbulan limbah kimia</label>
                                <div class="input-group">
                                  <input
                                    id="rataTimbulanLimbahKimia"
                                    v-model="
                                      limbah_padat.limbah_non_infeksius[
                                        'limbah_kimia'
                                      ].dataPenyusun_desc
                                    "
                                    type="number"
                                    name="rataTimbulanLimbahKimia"
                                    class="form-control form-medium"
                                  >
                                  <div class="icon-group">
                                    kg/bulan
                                  </div>
                                </div>
                                <span class="span-catatan">Contoh : Reagen kadaluarsa, dll</span>
                              </div>
                              <div class="d-flex align-center per-form-added">
                                <label
                                  class="label-tipe-1"
                                  for="rataTimbulanLimbahSitotoksik"
                                >Timbulan limbah sitotoksik</label>
                                <div class="input-group">
                                  <input
                                    id="rataTimbulanLimbahSitotoksik"
                                    v-model="
                                      limbah_padat.limbah_non_infeksius[
                                        'limbah_sitotoksik'
                                      ].dataPenyusun_desc
                                    "
                                    type="number"
                                    name="rataTimbulanLimbahSitotoksik"
                                    class="form-control form-medium"
                                  >
                                  <div class="icon-group">
                                    kg/bulan
                                  </div>
                                </div>
                                <span class="span-catatan">Contoh : Limbah kemoterapi, dll</span>
                              </div>
                              <div class="d-flex align-center per-form-added">
                                <label
                                  class="label-tipe-1"
                                  for="rataTimbulanLimbahRadioAktif"
                                >Timbulan limbah radioaktif</label>
                                <div class="input-group">
                                  <input
                                    id="rataTimbulanLimbahRadioAktif"
                                    v-model="
                                      limbah_padat.limbah_non_infeksius[
                                        'limbah_radioaktif'
                                      ].dataPenyusun_desc
                                    "
                                    type="number"
                                    name="rataTimbulanLimbahRadioAktif"
                                    class="form-control form-medium"
                                  >
                                  <div class="icon-group">
                                    kg/bulan
                                  </div>
                                </div>
                                <span class="span-catatan">Contoh : Limbah radioterapi, radiofarmaka,
                                  radiodianosis dll</span>
                              </div>
                            </div>

                            <!-- IF Non-Infeksius Tidak -->
                            <div
                              v-if="
                                limbah_padat.limbah_non_infeksius[
                                  'apakah_dilakukan_pemilahan_noninfeksius'
                                ].dataPenyusun_desc == 2
                              "
                              class="added-form"
                            >
                              <div class="d-flex align-center per-form-added">
                                <label
                                  class="label-tipe-1"
                                  for="totalLimbahNoninfekius"
                                >Timbulan total limbah medis non infeksius</label>
                                <div class="input-group">
                                  <input
                                    id="totalLimbahNoninfekius"
                                    v-model="
                                      limbah_padat.limbah_non_infeksius[
                                        'jumlah_total_limbah_non_infeksius'
                                      ].dataPenyusun_desc
                                    "
                                    type="number"
                                    name="totalLimbahNoninfekius"
                                    class="form-control form-medium"
                                  >
                                  <div class="icon-group">
                                    kg/bulan
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- V Else Soal 0 = Tidak -->
                  <div
                    v-if="
                      limbah_padat.limbah_padat[
                        'apakah_dilakukan_pemilahan_limbah_padat?'
                      ].dataPenyusun_desc == 2
                    "
                  >
                    <div class="border-soal">
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-1"
                            for="limbah-organik"
                          >Jumlah Total Limbah</label>
                          <div class="input-group">
                            <input
                              id="limbah-organik"
                              v-model="
                                limbah_padat.limbah_padat[
                                  'jumlah_total_limbah_padat'
                                ].dataPenyusun_desc
                              "
                              type="number"
                              name="limbah-organik"
                              class="form-control form-medium"
                            >
                            <div class="icon-group">
                              kg/bulan
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </v-tab-item>

              <!-- Tab 2 -->
              <v-tab-item value="tabs2">
                <v-card
                  v-if="sumber_daya"
                  class="mh-300 custom_card pa-5"
                >
                  <!-- Soal 1 -->
                  <div class="border-soal">
                    <div class="title-soal-form">
                      <h6>Unit Khusus / Organisasi Kesehatan Lingkungan</h6>
                    </div>
                    <div class="d-flex align-center">
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>
                            Apakah terdapat unit yang bertanggung jawab terhadap kesehatan lingkungan ?
                          </p>
                        </div>
                        <v-radio-group
                          v-model="
                            sumber_daya
                              .unit_khusus___organisasi_kesehatan_lingkungan[
                                'apakah_terdapat_unit_khusus_kesehatan_lingkungan?'
                              ].dataPenyusun_desc
                          "
                          row
                          dense
                          hide-details
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          />
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="0"
                          />
                        </v-radio-group>
                      </div>
                      <div v-if="dataConfirm">
                        <v-btn
                          v-if="
                            dataConfirm.tab2
                              .verification_kode_indikator_waste_data[0]
                              .status == '1'
                          "
                          depressed
                          color="grey lighten-2"
                          dark
                          @click="
                            dataConfirm.tab2.verification_kode_indikator_waste_data[0].status =
                              '0'
                          "
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                        <v-btn
                          v-if="
                            dataConfirm.tab2
                              .verification_kode_indikator_waste_data[0]
                              .status == '0'
                          "
                          depressed
                          color="primary"
                          @click="
                            dataConfirm.tab2.verification_kode_indikator_waste_data[0].status =
                              '1'
                          "
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                  </div>

                  <!-- Soal 2 -->
                  <div class="border-soal">
                    <div class="title-soal-form">
                      <h6>Petugas Kesehatan Lingkungan</h6>
                    </div>
                    <div class="added-form">
                      <div class="d-flex align-center per-form-added">
                        <div class="d-flex justify-between w-100 align-center">
                          <div class="d-flex align-center">
                            <label
                              class="label-tipe-2"
                              for="jumlahPetugasKesehatanLingkungan"
                            >
                              Berapa jumlah petugas kesehatan lingkungan ?</label>
                            <div class="input-group">
                              <input
                                id="jumlahPetugasKesehatanLingkungan"
                                v-model="
                                  sumber_daya.petugas_kesehatan_lingkungan[
                                    'jumlah_petugas_kesehatan_lingkungan'
                                  ].dataPenyusun_desc
                                "
                                type="number"
                                name="jumlahPetugasKesehatanLingkungan"
                                class="form-control form-medium"
                              >
                              <div class="icon-group">
                                Orang
                              </div>
                            </div>
                          </div>
                          <div v-if="dataConfirm">
                            <v-btn
                              v-if="
                                dataConfirm.tab2
                                  .verification_kode_indikator_waste_data[1]
                                  .status == '1'
                              "
                              depressed
                              color="grey lighten-2"
                              dark
                              @click="
                                dataConfirm.tab2.verification_kode_indikator_waste_data[1].status =
                                  '0'
                              "
                            >
                              <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                            </v-btn>
                            <v-btn
                              v-if="
                                dataConfirm.tab2
                                  .verification_kode_indikator_waste_data[1]
                                  .status == '0'
                              "
                              depressed
                              color="primary"
                              @click="
                                dataConfirm.tab2.verification_kode_indikator_waste_data[1].status =
                                  '1'
                              "
                            >
                              <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                            </v-btn>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Soal 3 -->
                  <div class="border-soal">
                    <div class="title-soal-form">
                      <h6>Petugas Pengelola Limbah B3</h6>
                    </div>
                    <div class="added-form">
                      <div class="d-flex align-center per-form-added">
                        <div class="d-flex w-100 justify-between">
                          <div class="d-flex align-center">
                            <label
                              class="label-tipe-2"
                              for="jumlahPetugasPengelolaLimbah"
                            >
                              Berapa jumlah petugas pengelola limbah ?</label>
                            <div class="input-group">
                              <input
                                id="jumlahPetugasPengelolaLimbah"
                                v-model="
                                  sumber_daya.petugas_pengelola_limbah_b3[
                                    'jumlah_petugas_pengelola_limbah'
                                  ].dataPenyusun_desc
                                "
                                type="number"
                                name="jumlahPetugasPengelolaLimbah"
                                class="form-control form-medium"
                              >
                              <div class="icon-group">
                                Orang
                              </div>
                            </div>
                          </div>
                          <div v-if="dataConfirm">
                            <v-btn
                              v-if="
                                dataConfirm.tab2
                                  .verification_kode_indikator_waste_data[2]
                                  .status == '1'
                              "
                              depressed
                              color="grey lighten-2"
                              dark
                              @click="
                                dataConfirm.tab2.verification_kode_indikator_waste_data[2].status =
                                  '0'
                              "
                            >
                              <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                            </v-btn>
                            <v-btn
                              v-if="
                                dataConfirm.tab2
                                  .verification_kode_indikator_waste_data[2]
                                  .status == '0'
                              "
                              depressed
                              color="primary"
                              @click="
                                dataConfirm.tab2.verification_kode_indikator_waste_data[2].status =
                                  '1'
                              "
                            >
                              <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                            </v-btn>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Soal 4 -->
                  <div class="border-soal">
                    <div class="title-soal-form">
                      <h6>Alokasi Dana / Anggaran (Tahunan)</h6>
                    </div>
                    <div class="added-form">
                      <div class="d-flex align-center per-form-added">
                        <div class="d-flex w-100 justify-between">
                          <div class="d-flex align-center">
                            <label
                              class="label-tipe-2"
                              for="jumlahPetugasPengelolaLimbah"
                            >
                              Berapa jumlah dana atau anggaran pengelolaan limbah dalam setahun</label>
                            <div class="input-group">
                              <div class="icon-group">
                                Rp
                              </div>
                              <input
                                id="jumlahPetugasPengelolaLimbah"
                                v-model="
                                  sumber_daya.alokasi_dana___anggaran[
                                    'jumlah_dana___anggaran_kelola_limbah'
                                  ].dataPenyusun_desc
                                "
                                type="number"
                                name="jumlahPetugasPengelolaLimbah"
                                class="form-control form-medium"
                              >
                            </div>
                          </div>
                          <div v-if="dataConfirm">
                            <v-btn
                              v-if="
                                dataConfirm.tab2
                                  .verification_kode_indikator_waste_data[3]
                                  .status == '1'
                              "
                              depressed
                              color="grey lighten-2"
                              dark
                              @click="
                                dataConfirm.tab2.verification_kode_indikator_waste_data[3].status =
                                  '0'
                              "
                            >
                              <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                            </v-btn>
                            <v-btn
                              v-if="
                                dataConfirm.tab2
                                  .verification_kode_indikator_waste_data[3]
                                  .status == '0'
                              "
                              depressed
                              color="primary"
                              @click="
                                dataConfirm.tab2.verification_kode_indikator_waste_data[3].status =
                                  '1'
                              "
                            >
                              <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                            </v-btn>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex align-center per-form-added">
                        <label
                          class="label-tipe-2"
                          for="jumlahPetugasPengelolaLimbah"
                        >
                          Berapa jumlah dana atau anggaran kesehatan lingkungan dalam setahun</label>
                        <div class="input-group">
                          <div class="icon-group">
                            Rp
                          </div>
                          <input
                            id="jumlahPetugasPengelolaLimbah"
                            v-model="
                              sumber_daya.alokasi_dana___anggaran[
                                'jumlah_dana___anggaran_kesehatan_lingkungan'
                              ].dataPenyusun_desc
                            "
                            type="number"
                            name="jumlahPetugasPengelolaLimbah"
                            class="form-control form-medium"
                          >
                        </div>
                      </div>
                      <div class="d-flex align-center per-form-added">
                        <label
                          class="label-tipe-2"
                          for="jumlahPetugasPengelolaLimbah"
                        >
                          Berapa jumlah dana atau anggaran keseluruhan Fasyankes dalam setahun</label>
                        <div class="input-group">
                          <div class="icon-group">
                            Rp
                          </div>
                          <input
                            id="jumlahPetugasPengelolaLimbah"
                            v-model="
                              sumber_daya.alokasi_dana___anggaran[
                                'jumlah_dana___anggaran_keseluruhan_fasyankes'
                              ].dataPenyusun_desc
                            "
                            type="number"
                            name="jumlahPetugasPengelolaLimbah"
                            class="form-control form-medium"
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Soal 5 -->
                  <div class="border-soal">
                    <div class="title-soal-form">
                      <h6>Dokumen Izin Lingkungan</h6>
                    </div>
                    <div class="d-flex w-100 align-center">
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>Apakah terdapat dokumen izin lingkungan ?</p>
                        </div>
                        <v-radio-group
                          v-model="
                            sumber_daya.dokumen_izin_lingkungan[
                              'apakah_terdapat_dokumen_izin_lingkungan?'
                            ].dataPenyusun_desc
                          "
                          row
                          dense
                          hide-details
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          />
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="0"
                          />
                        </v-radio-group>
                      </div>
                      <div v-if="dataConfirm">
                        <v-btn
                          v-if="
                            dataConfirm.tab2
                              .verification_kode_indikator_waste_data[4]
                              .status == '1'
                          "
                          depressed
                          color="grey lighten-2"
                          dark
                          @click="
                            dataConfirm.tab2.verification_kode_indikator_waste_data[4].status =
                              '0'
                          "
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                        <v-btn
                          v-if="
                            dataConfirm.tab2
                              .verification_kode_indikator_waste_data[4]
                              .status == '0'
                          "
                          depressed
                          color="primary"
                          @click="
                            dataConfirm.tab2.verification_kode_indikator_waste_data[4].status =
                              '1'
                          "
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                    <div class="added-form">
                      <div class="d-flex align-center per-form-added">
                        <div class="input-group">
                          <div class="icon-group">
                            Tahun
                          </div>
                          <input
                            id="totalLimbahNoninfekius"
                            v-model="
                              sumber_daya.dokumen_izin_lingkungan[
                                'tahun_izin_dokumen_lingkungan'
                              ].dataPenyusun_desc
                            "
                            type="number"
                            name="totalLimbahNoninfekius"
                            class="form-control form-medium"
                          >
                        </div>
                        <div class="px-3">
                          -
                        </div>
                        <div class="input-group">
                          <div class="icon-group">
                            No
                          </div>
                          <input
                            id="totalLimbahNoninfekius"
                            v-model="
                              sumber_daya.dokumen_izin_lingkungan[
                                'nomor_izin_dokumen_lingkungan'
                              ].dataPenyusun_desc
                            "
                            type="number"
                            name="totalLimbahNoninfekius"
                            class="form-control form-medium"
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Soal 6 -->
                  <div class="border-soal">
                    <div class="title-soal-form">
                      <h6>
                        Kebijakan Tertulis (SE/SK) Mengenai Pengelolaan Limbah
                        Medis/B3
                      </h6>
                    </div>
                    <div class="d-flex w-100 align-center">
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>Apakah terdapat kebijakan tertulis (SE/SK) mengenai pengelolaan limbah Fasyankes ?</p>
                        </div>
                        <v-radio-group
                          v-model="
                            sumber_daya
                              .kebijakan_tertulis__se_sk__mengenai_pengelolaan_limbah_medis_b3[
                                'apakah_terdapat_kebijakan_tertulis_mengenai_pengelolaan_limbah_medis_b3_'
                              ].dataPenyusun_desc
                          "
                          row
                          dense
                          hide-details
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          />
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="0"
                          />
                        </v-radio-group>
                      </div>
                      <div v-if="dataConfirm">
                        <v-btn
                          v-if="
                            dataConfirm.tab2
                              .verification_kode_indikator_waste_data[5]
                              .status == '1'
                          "
                          depressed
                          color="grey lighten-2"
                          dark
                          @click="
                            dataConfirm.tab2.verification_kode_indikator_waste_data[5].status =
                              '0'
                          "
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                        <v-btn
                          v-if="
                            dataConfirm.tab2
                              .verification_kode_indikator_waste_data[5]
                              .status == '0'
                          "
                          depressed
                          color="primary"
                          @click="
                            dataConfirm.tab2.verification_kode_indikator_waste_data[5].status =
                              '1'
                          "
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                    <div class="added-form">
                      <div class="d-flex align-center per-form-added">
                        <div class="input-group">
                          <div class="icon-group">
                            Tahun
                          </div>
                          <input
                            id="totalLimbahNoninfekius"
                            v-model="
                              sumber_daya
                                .kebijakan_tertulis__se_sk__mengenai_pengelolaan_limbah_medis_b3[
                                  'tahun_kebijakan_dokumen_lingkungan'
                                ].dataPenyusun_desc
                            "
                            type="number"
                            name="totalLimbahNoninfekius"
                            class="form-control form-medium"
                          >
                        </div>
                        <div class="px-3">
                          -
                        </div>
                        <div class="input-group">
                          <div class="icon-group">
                            No
                          </div>
                          <input
                            id="totalLimbahNoninfekius"
                            v-model="
                              sumber_daya
                                .kebijakan_tertulis__se_sk__mengenai_pengelolaan_limbah_medis_b3[
                                  'nomor_kebijakan_dokumen_lingkungan'
                                ].dataPenyusun_desc
                            "
                            type="number"
                            name="totalLimbahNoninfekius"
                            class="form-control form-medium"
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Soal 7 -->
                  <div class="border-soal">
                    <div class="title-soal-form">
                      <h6>Prosedur Pengelolaan Limbah (SOP)</h6>
                    </div>
                    <div class="d-flex w-100 align-center">
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>
                            Apakah terdapat prosedur pengelolaan limbah (SOP) ?
                          </p>
                        </div>
                        <v-radio-group
                          v-model="
                            sumber_daya.prosedur_pengelolaan_limbah__sop_[
                              'apakah_terdapat_prosedur_pengelolaan_limbah__sop___'
                            ].dataPenyusun_desc
                          "
                          row
                          dense
                          hide-details
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          />
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="0"
                          />
                        </v-radio-group>
                      </div>
                      <div v-if="dataConfirm">
                        <v-btn
                          v-if="
                            dataConfirm.tab2
                              .verification_kode_indikator_waste_data[6]
                              .status == '1'
                          "
                          depressed
                          color="grey lighten-2"
                          dark
                          @click="
                            dataConfirm.tab2.verification_kode_indikator_waste_data[6].status =
                              '0'
                          "
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                        <v-btn
                          v-if="
                            dataConfirm.tab2
                              .verification_kode_indikator_waste_data[6]
                              .status == '0'
                          "
                          depressed
                          color="primary"
                          @click="
                            dataConfirm.tab2.verification_kode_indikator_waste_data[6].status =
                              '1'
                          "
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                    <div class="added-form">
                      <div class="d-flex align-center per-form-added">
                        <div class="input-group">
                          <div class="icon-group">
                            Tahun
                          </div>
                          <input
                            id="totalLimbahNoninfekius"
                            v-model="
                              sumber_daya.prosedur_pengelolaan_limbah__sop_[
                                'tahun_sop'
                              ].dataPenyusun_desc
                            "
                            type="number"
                            name="totalLimbahNoninfekius"
                            class="form-control form-medium"
                          >
                        </div>
                        <div class="px-3">
                          -
                        </div>
                        <div class="input-group">
                          <div class="icon-group">
                            No
                          </div>
                          <input
                            id="totalLimbahNoninfekius"
                            v-model="
                              sumber_daya.prosedur_pengelolaan_limbah__sop_[
                                'nomor_sop'
                              ].dataPenyusun_desc
                            "
                            type="number"
                            name="totalLimbahNoninfekius"
                            class="form-control form-medium"
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Soal 8 -->
                  <div class="border-soal">
                    <div class="title-soal-form">
                      <h6>Penyediaan Wadah</h6>
                    </div>
                    <div class="d-flex align-center w-100">
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>Apakah kuantitas wadah memadai ?</p>
                        </div>
                        <v-radio-group
                          v-model="
                            sumber_daya.penyediaan_wadah['kualitas_wadah']
                              .dataPenyusun_desc
                          "
                          row
                          dense
                          hide-details
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          />
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="0"
                          />
                        </v-radio-group>
                      </div>
                      <div v-if="dataConfirm">
                        <v-btn
                          v-if="
                            dataConfirm.tab2
                              .verification_kode_indikator_waste_data[7]
                              .status == '1'
                          "
                          depressed
                          color="grey lighten-2"
                          dark
                          @click="
                            dataConfirm.tab2.verification_kode_indikator_waste_data[7].status =
                              '0'
                          "
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                        <v-btn
                          v-if="
                            dataConfirm.tab2
                              .verification_kode_indikator_waste_data[7]
                              .status == '0'
                          "
                          depressed
                          color="primary"
                          @click="
                            dataConfirm.tab2.verification_kode_indikator_waste_data[7].status =
                              '1'
                          "
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                    <div class="added-form">
                      <div class="d-flex align-center per-form-added">
                        <div class="info-form">
                          <img
                            src="../../../assets/img/info-icon.svg"
                            alt=""
                          >
                          <h6>Ya :</h6>
                          <p>
                            Wadah limbah tersedia di semua tempat penghasil limbah dengan jumlah dan kapasitas wadah yang memadai
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Soal 9 -->
                  <div class="border-soal">
                    <div class="title-soal-form">
                      <h6>Penyediaan Alat Angkut</h6>
                    </div>
                    <div class="d-flex w-100 align-center">
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>Apakah kuantitas alat angkut memadai ?</p>
                        </div>
                        <v-radio-group
                          v-model="
                            sumber_daya.penyediaan_alat_angkut[
                              'kualitas_alat_angkut'
                            ].dataPenyusun_desc
                          "
                          row
                          dense
                          hide-details
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          />
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="0"
                          />
                        </v-radio-group>
                      </div>
                      <div v-if="dataConfirm">
                        <v-btn
                          v-if="
                            dataConfirm.tab2
                              .verification_kode_indikator_waste_data[8]
                              .status == '1'
                          "
                          depressed
                          color="grey lighten-2"
                          dark
                          @click="
                            dataConfirm.tab2.verification_kode_indikator_waste_data[8].status =
                              '0'
                          "
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                        <v-btn
                          v-if="
                            dataConfirm.tab2
                              .verification_kode_indikator_waste_data[8]
                              .status == '0'
                          "
                          depressed
                          color="primary"
                          @click="
                            dataConfirm.tab2.verification_kode_indikator_waste_data[8].status =
                              '1'
                          "
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                    <div class="added-form">
                      <div class="d-flex align-center per-form-added">
                        <div class="info-form">
                          <img
                            src="../../../assets/img/info-icon.svg"
                            alt=""
                          >
                          <h6>Ya :</h6>
                          <p>Alat angkut limbah tersedia dengan jumlah dan kapasitas yang memadai untuk mengumpulkan limbah dari sumber ke penyimpanan</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Soal 10 -->
                  <div class="border-soal">
                    <div class="title-soal-form">
                      <h6>Penyediaan Alat Pelindung Diri (APD)</h6>
                    </div>
                    <div class="d-flex w-100 align-center">
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>Apakah kuantitas Alat Pelindung Diri (APD) memadai ?</p>
                        </div>
                        <v-radio-group
                          v-model="
                            sumber_daya.penyediaan_alat_pelindung_diri__apd_[
                              'kualitas_apd'
                            ].dataPenyusun_desc
                          "
                          row
                          dense
                          hide-details
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          />
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="0"
                          />
                        </v-radio-group>
                      </div>
                      <div v-if="dataConfirm">
                        <v-btn
                          v-if="
                            dataConfirm.tab2
                              .verification_kode_indikator_waste_data[9]
                              .status == '1'
                          "
                          depressed
                          color="grey lighten-2"
                          dark
                          @click="
                            dataConfirm.tab2.verification_kode_indikator_waste_data[9].status =
                              '0'
                          "
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                        <v-btn
                          v-if="
                            dataConfirm.tab2
                              .verification_kode_indikator_waste_data[9]
                              .status == '0'
                          "
                          depressed
                          color="primary"
                          @click="
                            dataConfirm.tab2.verification_kode_indikator_waste_data[9].status =
                              '1'
                          "
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                    <div class="added-form">
                      <div class="d-flex align-center per-form-added">
                        <div class="info-form">
                          <img
                            src="../../../assets/img/info-icon.svg"
                            alt=""
                          >
                          <h6>Ya :</h6>
                          <p>APD tersedia dengan jenis lengkap serta jumlah dan ukuran yang memadai untuk digunakan petugas</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Soal 11 -->
                  <div class="border-soal">
                    <div class="title-soal-form">
                      <h6>Timbangan Medis</h6>
                    </div>
                    <div class="d-flex w-100 align-center">
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>
                            Apakah terdapat timbangan limbah medis yang dapat digunakan ?
                          </p>
                        </div>
                        <v-radio-group
                          v-model="
                            sumber_daya.timbangan_medis[
                              'ketersediaan_dan_status_timbangan_limbah_medis'
                            ].dataPenyusun_desc
                          "
                          row
                          dense
                          hide-details
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="2"
                          />
                          <!-- <v-radio
                            color="#00ccb6"
                            label="Ada - Tidak Berfungsi"
                            value="1"
                          ></v-radio> -->
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="0"
                          />
                        </v-radio-group>
                      </div>
                      <div v-if="dataConfirm">
                        <v-btn
                          v-if="
                            dataConfirm.tab2
                              .verification_kode_indikator_waste_data[10]
                              .status == '1'
                          "
                          depressed
                          color="grey lighten-2"
                          dark
                          @click="
                            dataConfirm.tab2.verification_kode_indikator_waste_data[10].status =
                              '0'
                          "
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                        <v-btn
                          v-if="
                            dataConfirm.tab2
                              .verification_kode_indikator_waste_data[10]
                              .status == '0'
                          "
                          depressed
                          color="primary"
                          @click="
                            dataConfirm.tab2.verification_kode_indikator_waste_data[10].status =
                              '1'
                          "
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                  </div>

                  <!-- Soal 12 -->
                  <div class="border-soal">
                    <div class="title-soal-form">
                      <h6>Pencatatan Limbah Medis (Log Book)</h6>
                    </div>
                    <div class="d-flex w-100 align-center">
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>
                            Apakah terdapat pencatatan timbulan limbah medis (log book) ?
                          </p>
                        </div>
                        <v-radio-group
                          v-model="
                            sumber_daya.pencatatan_limbah_medis__log_book_[
                              'apakah_terdapat_pencatatan_limbah_medis__log_book_'
                            ].dataPenyusun_desc
                          "
                          row
                          dense
                          hide-details
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          />
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="0"
                          />
                        </v-radio-group>
                      </div>
                      <div v-if="dataConfirm">
                        <v-btn
                          v-if="
                            dataConfirm.tab2
                              .verification_kode_indikator_waste_data[11]
                              .status == '1'
                          "
                          depressed
                          color="grey lighten-2"
                          dark
                          @click="
                            dataConfirm.tab2.verification_kode_indikator_waste_data[11].status =
                              '0'
                          "
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                        <v-btn
                          v-if="
                            dataConfirm.tab2
                              .verification_kode_indikator_waste_data[11]
                              .status == '0'
                          "
                          depressed
                          color="primary"
                          @click="
                            dataConfirm.tab2.verification_kode_indikator_waste_data[11].status =
                              '1'
                          "
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                  </div>

                  <!-- Soal 13 -->
                  <div class="border-soal">
                    <div class="title-soal-form">
                      <h6>Cold storage / Freezer Khusus Limbah Medis</h6>
                    </div>
                    <div class="d-flex w-100 align-center">
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>
                            Apakah terdapat cold storage/freezer limbah infeksius yang dapat digunakan ?
                          </p>
                        </div>
                        <v-radio-group
                          v-model="
                            sumber_daya
                              .cold_storage___freezer_khusus_limbah_medis[
                                'ketersediaan_dan_status_cold_storage___freezer_khusus_limbah_medis'
                              ].dataPenyusun_desc
                          "
                          row
                          dense
                          hide-details
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="2"
                          />
                          <!-- <v-radio
                            color="#00ccb6"
                            label="Ada - Tidak Berfungsi"
                            value="1"
                          ></v-radio> -->
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="0"
                          />
                        </v-radio-group>
                      </div>
                      <div v-if="dataConfirm">
                        <v-btn
                          v-if="
                            dataConfirm.tab2
                              .verification_kode_indikator_waste_data[12]
                              .status == '1'
                          "
                          depressed
                          color="grey lighten-2"
                          dark
                          @click="
                            dataConfirm.tab2.verification_kode_indikator_waste_data[12].status =
                              '0'
                          "
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                        <v-btn
                          v-if="
                            dataConfirm.tab2
                              .verification_kode_indikator_waste_data[12]
                              .status == '0'
                          "
                          depressed
                          color="primary"
                          @click="
                            dataConfirm.tab2.verification_kode_indikator_waste_data[12].status =
                              '1'
                          "
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                  </div>

                  <!-- Soal 14 -->
                  <div
                    v-if="
                      sumber_daya.cold_storage___freezer_khusus_limbah_medis[
                        'ketersediaan_dan_status_cold_storage___freezer_khusus_limbah_medis'
                      ].dataPenyusun_desc == 2"
                    class="border-soal"
                  >
                    <div class="title-soal-form">
                      <h6>Kapasitas Cold Storage / Freezer</h6>
                    </div>
                    <div class="added-form">
                      <div class="d-flex align-center per-form-added">
                        <div class="d-flex w-100 justify-between align-center">
                          <div class="d-flex align-center">
                            <label
                              class="label-tipe-2"
                              for="jumlahPetugasPengelolaLimbah"
                            >
                              Jumlah Kapasitas cold storage / freezer
                            </label>
                            <div class="input-group">
                              <input
                                id="jumlahPetugasPengelolaLimbah"
                                v-model="
                                  sumber_daya.kapasitas_cold_storage___freezer[
                                    'jumlah_kapasitas_cold_storage___freezer'
                                  ].dataPenyusun_desc
                                "
                                type="number"
                                name="jumlahPetugasPengelolaLimbah"
                                class="form-control form-medium"
                              >
                              <div class="icon-group">
                                m³
                              </div>
                            </div>
                          </div>
                          <div v-if="dataConfirm">
                            <v-btn
                              v-if="
                                dataConfirm.tab2
                                  .verification_kode_indikator_waste_data[13]
                                  .status == '1'
                              "
                              depressed
                              color="grey lighten-2"
                              dark
                              @click="
                                dataConfirm.tab2.verification_kode_indikator_waste_data[13].status =
                                  '0'
                              "
                            >
                              <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                            </v-btn>
                            <v-btn
                              v-if="
                                dataConfirm.tab2
                                  .verification_kode_indikator_waste_data[13]
                                  .status == '0'
                              "
                              depressed
                              color="primary"
                              @click="
                                dataConfirm.tab2.verification_kode_indikator_waste_data[13].status =
                                  '1'
                              "
                            >
                              <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                            </v-btn>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </v-tab-item>

              <!-- Tab 3 -->
              <v-tab-item value="tabs3">
                <v-card
                  v-if="alur"
                  class="mh-300 custom_card pa-5"
                >
                  <!-- Soal 1 -->
                  <div class="border-soal">
                    <div class="d-flex justify-between mb-4">
                      <div class="title-soal-form d-flex align-center">
                        <h6>Pewadahan</h6>
                      </div>
                      
                    </div>
                    <div class="d-flex">
                      <div
                        :class="
                          code == '3'
                            ? 'soal-form bors-sol side_left'
                            : 'soal-form bors-sol full_width'
                        "
                      >
                        <div class="questions">
                          <p>
                            Apakah tersedia plastik warna hitam untuk limbah domestik ?
                          </p>
                        </div>
                        <v-radio-group
                          v-model="
                            alur.jenis_pewadahan_limbah_domestik[
                              'ketersediaan_plastik_hitam_untuk_limbah_domestik'
                            ].dataPenyusun_desc
                          "
                          row
                          dense
                          hide-details
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ada"
                            value="1"
                          />
                          <v-radio
                            color="#00ccb6"
                            label="Tidak ada"
                            value="2"
                          />
                        </v-radio-group>
                      </div>
                      <div v-if="dataConfirm">
                        <v-btn
                          depressed
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[3]
                              .status == '1'
                          "
                          @click="
                            dataConfirm.tab3.verification_kode_indikator_waste_data[3].status =
                              '0'
                          "
                          color="grey lighten-2"
                          dark
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                        <v-btn
                          depressed
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[3]
                              .status == '0'
                          "
                          @click="
                            dataConfirm.tab3.verification_kode_indikator_waste_data[3].status =
                              '1'
                          "
                          color="primary"
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div :class="code == '3' ? 'side_left' : 'full_width'">
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>
                              Apakah tersedia plastik warna kuning untuk limbah medis infeksius dan patologi ?
                            </p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur.jenis_pewadahan_limbah_medis_infeksius[
                                'ketersediaan_plastik_kuning_untuk_limbah_medis_infeksius_dan_patologi'
                              ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak ada"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                      </div>
                      <div class="mt-3" v-if="dataConfirm">
                        <v-btn
                          depressed
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[1]
                              .status == '1'
                          "
                          @click="
                            dataConfirm.tab3.verification_kode_indikator_waste_data[1].status =
                              '0'
                          "
                          color="grey lighten-2"
                          dark
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                        <v-btn
                          depressed
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[1]
                              .status == '0'
                          "
                          @click="
                            dataConfirm.tab3.verification_kode_indikator_waste_data[1].status =
                              '1'
                          "
                          color="primary"
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div :class="code == '3' ? 'side_left' : 'full_width'">
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>Apakah tersedia safety box untuk limbah tajam ?</p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur.jenis_pewadahan_limbah_medis_infeksius[
                                'ketersediaan_safety_box_untuk_limbah_tajam'
                              ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak ada"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                      </div>
                      <div class="mt-3" v-if="dataConfirm">
                        <v-btn
                          depressed
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[4]
                              .status == '1'
                          "
                          @click="
                            dataConfirm.tab3.verification_kode_indikator_waste_data[4].status =
                              '0'
                          "
                          color="grey lighten-2"
                          dark
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                        <v-btn
                          depressed
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[4]
                              .status == '0'
                          "
                          @click="
                            dataConfirm.tab3.verification_kode_indikator_waste_data[4].status =
                              '1'
                          "
                          color="primary"
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                    <div class="d-flex border-soal mt-3">
                      <div :class="code == '3' ? 'side_left' : 'full_width'">
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>
                              Apakah tersedia plastik warna ungu untuk limbah sitotoksik ?
                            </p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur.jenis_pewadahan_limbah_medis_non_infeksius[
                                'ketersediaan_plastik_warna_ungu_untuk_sitotoksis'
                              ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak ada"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>
                              Apakah tersedia plastik warna merah untuk limbah radioaktif ?
                            </p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur.jenis_pewadahan_limbah_medis_non_infeksius[
                                'ketersediaan_plastik_warna_merah_untuk_radioaktif'
                              ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak ada"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>
                              Apakah tersedia plastik warna cokelat untuk limbah farmasi ?
                            </p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur.jenis_pewadahan_limbah_medis_non_infeksius[
                                'ketersediaan_plastik_warna_coklat_untuk_farmasi'
                              ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak ada"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>
                              Apakah tersedia plastik warna cokelat untuk limbah kimia ?
                            </p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur.jenis_pewadahan_limbah_medis_non_infeksius[
                                'ketersediaan_plastik_warna_coklat_untuk_kimia'
                              ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak ada"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                      </div>
                      <div class="mt-3" v-if="dataConfirm">
                        <v-btn
                          depressed
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[5]
                              .status == '1'
                          "
                          @click="
                            dataConfirm.tab3.verification_kode_indikator_waste_data[5].status =
                              '0'
                          "
                          color="grey lighten-2"
                          dark
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                        <v-btn
                          depressed
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[5]
                              .status == '0'
                          "
                          @click="
                            dataConfirm.tab3.verification_kode_indikator_waste_data[5].status =
                              '1'
                          "
                          color="primary"
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                  </div>

                  <!-- Soal 2 -->
                  <div class="border-soal">
                    <div class="d-flex justify-between mb-4">
                      <div class="title-soal-form d-flex align-center">
                        <h6>Apakah pewadahan limbah medis memiliki kriteria sebagai berikut :</h6>
                      </div>
                      <div class="mt-3" v-if="dataConfirm">
                        <v-btn
                          depressed
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[6]
                              .status == '1'
                          "
                          @click="
                            dataConfirm.tab3.verification_kode_indikator_waste_data[6].status =
                              '0'
                          "
                          color="grey lighten-2"
                          dark
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                        <v-btn
                          depressed
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[6]
                              .status == '0'
                          "
                          @click="
                            dataConfirm.tab3.verification_kode_indikator_waste_data[6].status =
                              '1'
                          "
                          color="primary"
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div :class="code == '3' ? 'side_left' : 'full_width'">
                        <div class="soal-form bors-sol">
                          <div class="questions">
                            <p>Tertutup</p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur.spesifikasi_pewadahan_plastik['tertutup']
                                .dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>Tidak Bocor</p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur.spesifikasi_pewadahan_plastik[
                                'tidak_bocor'
                              ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>Label Sesuai</p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur.spesifikasi_pewadahan_plastik[
                                'label_sesuai'
                              ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>Simbol sesuai</p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur.spesifikasi_pewadahan_plastik[
                                'simbol_sesuai'
                              ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>Mudah dibersihkan</p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur.spesifikasi_pewadahan_plastik[
                                'mudah_dibersihkan'
                              ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>Warna sesuai</p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur.spesifikasi_pewadahan_plastik[
                                'warna_sesuai'
                              ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                      </div>
                      
                    </div>
                  </div>

                  <!-- Soal 3 -->
                  <div class="border-soal">
                    <div class="d-flex justify-between mb-4">
                      <div class="title-soal-form d-flex align-center">
                        <h6>Apakah pengangkutan/pengumpulan limbah medis memiliki kriteria sebagai berikut :</h6>
                      </div>
                      <div class="mt-3" v-if="dataConfirm">
                        <v-btn
                          depressed
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[7]
                              .status == '1'
                          "
                          @click="
                            dataConfirm.tab3.verification_kode_indikator_waste_data[7].status =
                              '0'
                          "
                          color="grey lighten-2"
                          dark
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                        <v-btn
                          depressed
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[7]
                              .status == '0'
                          "
                          @click="
                            dataConfirm.tab3.verification_kode_indikator_waste_data[7].status =
                              '1'
                          "
                          color="primary"
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div :class="code == '3' ? 'side_left' : 'full_width'">
                        <div class="soal-form bors-sol">
                          <div class="questions">
                            <p>Alat angkut khusus limbah</p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur
                                .spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                                  'khusus_alat_angkut_khusus_limbah'
                                ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>Wheel bin/wadah beroda</p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur
                                .spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                                  'wheel_bin_wadah_beroda'
                                ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>Tertutup</p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur
                                .spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                                  'tertutup'
                                ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>Jalur khusus</p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur
                                .spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                                  'jalur_khusus'
                                ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>Waktu khusus</p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur
                                .spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                                  'waktu_khusus'
                                ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>Tidak bocor</p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur
                                .spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                                  'tidak_bocor'
                                ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>Label sesuai</p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur
                                .spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                                  'label_sesuai'
                                ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>Simbol sesuai</p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur
                                .spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                                  'simbol_sesuai'
                                ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>Mudah dibersihkan</p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur
                                .spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                                  'mudah_dibersihkan'
                                ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>Warna sesuai</p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur
                                .spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                                  'warna_sesuai'
                                ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                      </div>
                      <div
                        v-if="code == '3'"
                        class="d-flex justify-end side_right"
                      >
                        <v-btn
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[7]
                              .status == '1'
                          "
                          dark
                          color="#00b4cc"
                          class="text-capitalize"
                          depressed
                        >
                          Sesuai
                        </v-btn>
                        <v-btn
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[7]
                              .status == '0'
                          "
                          dark
                          color="#28577A"
                          class="text-capitalize"
                          depressed
                        >
                          Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                  </div>

                  <!-- Soal 4 -->
                  <div class="border-soal">
                    <div class="d-flex justify-between mb-4">
                      <div class="title-soal-form d-flex align-center">
                        <h6>Dimana penyimpanan limbah medis dilakukan ?</h6>
                      </div>
                      <div class="mt-3" v-if="dataConfirm">
                        <v-btn
                          depressed
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[8]
                              .status == '1'
                          "
                          @click="
                            dataConfirm.tab3.verification_kode_indikator_waste_data[8].status =
                              '0'
                          "
                          color="grey lighten-2"
                          dark
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                        <v-btn
                          depressed
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[8]
                              .status == '0'
                          "
                          @click="
                            dataConfirm.tab3.verification_kode_indikator_waste_data[8].status =
                              '1'
                          "
                          color="primary"
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                    
                    <div class="d-flex">
                      <div :class="code == '3' ? 'side_left' : 'full_width'">
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>
                              Di Tempat Penyimpanan Sementara (TPS) limbah B3 berizin (di dalam area Fasyankes)
                            </p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur.ketersediaan_ruang_penyimpanan[
                                'di_tempat_penyimpanan_sementara__tps__limbah_b3_berizin__dalam_area_fasyankes_'
                              ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>
                              Di Tempat Penyimpanan Sementara (TPS) Limbah B3 depo pemindahan berizin (di luar area Fasyankes)
                            </p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur.ketersediaan_ruang_penyimpanan[
                                'di_tempat_penyimpanan_sementara__tps__limbah_b3_depo_pemindahan_berizin__diluar_area_fasyankes_'
                              ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>
                              Di tempat lainnya (gudang, ruang kosong, ruang terbuka, dll)
                            </p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur.ketersediaan_ruang_penyimpanan[
                                'lainnya__di_gudang,_ruang_kosong,_ruang_terbuka,_dll_'
                              ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>Tidak ada tempat penyimpanan</p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur.ketersediaan_ruang_penyimpanan[
                                'tidak_ada_penyimpanan'
                              ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                      </div>
                      <div
                        v-if="code == '3'"
                        class="d-flex justify-end side_right"
                      >
                        <v-btn
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[8]
                              .status == '1'
                          "
                          dark
                          color="#00b4cc"
                          class="text-capitalize"
                          depressed
                        >
                          Sesuai
                        </v-btn>
                        <v-btn
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[8]
                              .status == '0'
                          "
                          dark
                          color="#28577A"
                          class="text-capitalize"
                          depressed
                        >
                          Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                  </div>

                  <!-- Soal 5 -->
                  <div class="border-soal">
                    <div class="d-flex justify-between mb-4">
                      <div class="title-soal-form d-flex align-center">
                        <h6>Pengangkutan</h6>
                      </div>
                      <div class="mt-3" v-if="dataConfirm">
                        <v-btn
                          depressed
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[9]
                              .status == '1'
                          "
                          @click="
                            dataConfirm.tab3.verification_kode_indikator_waste_data[9].status =
                              '0'
                          "
                          color="grey lighten-2"
                          dark
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                        <v-btn
                          depressed
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[9]
                              .status == '0'
                          "
                          @click="
                            dataConfirm.tab3.verification_kode_indikator_waste_data[9].status =
                              '1'
                          "
                          color="primary"
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div :class="code == '3' ? 'side_left' : 'full_width'">
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>Apakah tersedia kendaraan pengangkut limbah roda tiga ?</p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur
                                .ketersediaan_pengangkutan_limbah_ke_tempat_pengolah[
                                  'jumlah_kendaraan_roda_tiga'
                                ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>Apakah tersedia kendaraan pengangkut limbah roda empat ?</p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur
                                .ketersediaan_pengangkutan_limbah_ke_tempat_pengolah[
                                  'jumlah_kendaraan_roda_empat'
                                ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>Apakah tersedia kendaraan pengangkut limbah dari perusahaan pengelola limbah ?</p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur
                                .ketersediaan_pengangkutan_limbah_ke_tempat_pengolah[
                                  'jumlah_kendaraan_perusahaan_pengangkut'
                                ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                      </div>
                      <div
                        v-if="code == '3'"
                        class="d-flex justify-end side_right"
                      >
                        <v-btn
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[9]
                              .status == '1'
                          "
                          dark
                          color="#00b4cc"
                          class="text-capitalize"
                          depressed
                        >
                          Sesuai
                        </v-btn>
                        <v-btn
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[9]
                              .status == '0'
                          "
                          dark
                          color="#28577A"
                          class="text-capitalize"
                          depressed
                        >
                          Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                  </div>

                  <!-- Soal 6 -->
                  <div class="border-soal">
                    <div class="d-flex justify-between mb-4">
                      <div class="title-soal-form d-flex align-center">
                        <h6>Ketersediaan Alat Pengolah Limbah Di Fasyankes</h6>
                      </div>
                      <div class="mt-3" v-if="dataConfirm">
                        <v-btn
                          depressed
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[10]
                              .status == '1'
                          "
                          @click="
                            dataConfirm.tab3.verification_kode_indikator_waste_data[10].status =
                              '0'
                          "
                          color="grey lighten-2"
                          dark
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                        <v-btn
                          depressed
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[10]
                              .status == '0'
                          "
                          @click="
                            dataConfirm.tab3.verification_kode_indikator_waste_data[10].status =
                              '1'
                          "
                          color="primary"
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                    
                    <div class="d-flex">
                      <div :class="code == '3' ? 'side_left' : 'full_width'">
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>
                              Apakah terdapat insinerator yang berfungsi sebagai alat pengolah limbah ?
                            </p>
                          </div>

                          <v-radio-group
                            v-model="
                              alur.ketersediaan_alat_pengolah_limbah[
                                'apakah_terdapat_pengolah_limbah_insinerator'
                              ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                              @click="clearKapasitasInsineratorData()"
                            />
                          </v-radio-group>
                        </div>
                        <div
                          v-if="
                            alur.
                              ketersediaan_alat_pengolah_limbah['apakah_terdapat_pengolah_limbah_insinerator'].dataPenyusun_desc == null ||
                              alur.
                                ketersediaan_alat_pengolah_limbah['apakah_terdapat_pengolah_limbah_insinerator'].dataPenyusun_desc == 1
                          "
                          class="added-form"
                        >
                          <div class="d-flex align-center per-form-added">
                            <label
                              class="label-tipe-2"
                              for="jumlahPetugasPengelolaLimbah"
                            >
                              Kapasitas insinerator :
                            </label>
                            <div class="input-group">
                              <input
                                id="jumlahPetugasPengelolaLimbah"
                                v-model="
                                  alur.ketersediaan_alat_pengolah_limbah[
                                    'kapasitas_operasinal_insinerator'
                                  ].dataPenyusun_desc
                                "
                                type="number"
                                name="jumlahPetugasPengelolaLimbah"
                                class="form-control form-medium"
                              >
                              <div class="icon-group">
                                Kg/jam
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>
                              Apakah terdapat autoclave/microwave yang berfungsi sebagai alat pengolah limbah ?
                            </p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur.ketersediaan_alat_pengolah_limbah[
                                'apakah_terdapat_pengolah_limbah_autoclave_microwave'
                              ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ada"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak Ada"
                              value="2"
                              @click="clearKapasitasAutoclaveData()"
                            />
                          </v-radio-group>
                        </div>
                        <div
                          v-if="
                            alur.ketersediaan_alat_pengolah_limbah['apakah_terdapat_pengolah_limbah_autoclave_microwave'].dataPenyusun_desc == null ||
                              alur.ketersediaan_alat_pengolah_limbah['apakah_terdapat_pengolah_limbah_autoclave_microwave'].dataPenyusun_desc == 1
                          "
                          class="added-form"
                        >
                          <div class="d-flex align-center per-form-added">
                            <label
                              class="label-tipe-2"
                              for="jumlahPetugasPengelolaLimbah"
                            >
                              Kapasitas autoclave/microwave :
                            </label>
                            <div class="input-group">
                              <input
                                id="jumlahPetugasPengelolaLimbah"
                                v-model="
                                  alur.ketersediaan_alat_pengolah_limbah[
                                    'kapasitas_operasinal_autoclave_microwave'
                                  ].dataPenyusun_desc
                                "
                                type="number"
                                name="jumlahPetugasPengelolaLimbah"
                                class="form-control form-medium"
                              >
                              <div class="icon-group">
                                Kg/jam
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="code == '3'"
                        class="d-flex justify-end side_right"
                      >
                        <v-btn
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[10]
                              .status == '1'
                          "
                          dark
                          color="#00b4cc"
                          class="text-capitalize"
                          depressed
                        >
                          Sesuai
                        </v-btn>
                        <v-btn
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[10]
                              .status == '0'
                          "
                          dark
                          color="#28577A"
                          class="text-capitalize"
                          depressed
                        >
                          Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                  </div>

                  <!-- Soal 7 -->
                  <div class="border-soal">
                     <div class="d-flex justify-between mb-4">
                      <div class="title-soal-form d-flex align-center">
                        <h6>Spesifikasi pengolahan limbah</h6>
                      </div>
                      <div class="mt-3" v-if="dataConfirm">
                        <v-btn
                          depressed
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[11]
                              .status == '1'
                          "
                          @click="
                            dataConfirm.tab3.verification_kode_indikator_waste_data[11].status =
                              '0'
                          "
                          color="grey lighten-2"
                          dark
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                        <v-btn
                          depressed
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[11]
                              .status == '0'
                          "
                          @click="
                            dataConfirm.tab3.verification_kode_indikator_waste_data[11].status =
                              '1'
                          "
                          color="primary"
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                   
                    <div class="d-flex">
                      <div :class="code == '3' ? 'side_left' : 'full_width'">
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>Apakah ingin menggunakan data sebelumnya ?</p>
                          </div>

                          <v-radio-group
                            v-model="
                              alur.spesifikasi_pengolahan_limbah[
                                'apakah_ingin_menggunakan_data_sebelumnya_'
                              ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>Apakah terdapat insinerator berizin sebagai alat pengolah limbah ?</p>
                          </div>

                          <v-radio-group
                            v-model="
                              alur.spesifikasi_pengolahan_limbah[
                                'diolah_dengan_insinerator'
                              ].dataPenyusun_desc
                            "
                            :disabled="
                              alur.spesifikasi_pengolahan_limbah[
                                'apakah_ingin_menggunakan_data_sebelumnya_'
                              ].dataPenyusun_desc != 2
                            "
                            row
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                        <div class="added-form">
                          <div class="d-flex align-center per-form-added">
                            <div class="input-group">
                              <div class="icon-group">
                                No
                              </div>
                              <input
                                id="totalLimbahNoninfekius"
                                v-model="
                                  alur.spesifikasi_pengolahan_limbah[
                                    'no_insinerator'
                                  ].dataPenyusun_desc
                                "
                                type="number"
                                :disabled="
                                  alur.spesifikasi_pengolahan_limbah[
                                    'diolah_dengan_insinerator'
                                  ].dataPenyusun_desc != 1
                                "
                                name="totalLimbahNoninfekius"
                                class="form-control form-medium"
                              >
                            </div>
                            <div class="px-3">
                              -
                            </div>
                            <div class="input-group">
                              <div
                                class="icon-group"
                                style="margin-right: 50px"
                              >
                                Tanggal izin
                              </div>
                              <v-menu
                                v-model="calendar1"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                :disabled="
                                  alur.spesifikasi_pengolahan_limbah[
                                    'diolah_dengan_insinerator'
                                  ].dataPenyusun_desc != 1
                                "
                                min-width="290px"
                              >
                                <template #activator="{ on, attrs }">
                                  <input
                                    v-model="
                                      alur.spesifikasi_pengolahan_limbah[
                                        'tangggal_izin_insinerator'
                                      ].dataPenyusun_desc
                                    "
                                    type="text"
                                    placeholder="mm/dd/yyyy"
                                    readonly
                                    v-bind="attrs"
                                    class="form-control form-medium"
                                    v-on="on"
                                  >
                                </template>
                                <v-date-picker
                                  v-model="
                                    alur.spesifikasi_pengolahan_limbah[
                                      'tangggal_izin_insinerator'
                                    ].dataPenyusun_desc
                                  "
                                  @input="calendar1 = false"
                                />
                              </v-menu>
                              <v-icon class="ml-2">
                                mdi-calendar
                              </v-icon>
                            </div>
                          </div>
                        </div>
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>
                              Apakah terdapat autoclave/microwave berizin sebagai alat pengolah limbah ?
                            </p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur.spesifikasi_pengolahan_limbah[
                                'apakah_terdapat_alat_pengolah_limbah_autoclave_microwave'
                              ].dataPenyusun_desc
                            "
                            row
                            :disabled="
                              alur.spesifikasi_pengolahan_limbah[
                                'apakah_ingin_menggunakan_data_sebelumnya_'
                              ].dataPenyusun_desc != 2
                            "
                            dense
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                        <div class="added-form">
                          <div class="d-flex align-center per-form-added">
                            <div class="input-group">
                              <div class="icon-group">
                                No
                              </div>
                              <input
                                id="totalLimbahNoninfekius"
                                v-model="
                                  alur.spesifikasi_pengolahan_limbah[
                                    'no_autoclave_microwave'
                                  ].dataPenyusun_desc
                                "
                                type="number"
                                :disabled="
                                  alur.spesifikasi_pengolahan_limbah[
                                    'apakah_terdapat_alat_pengolah_limbah_autoclave_microwave'
                                  ].dataPenyusun_desc != 1
                                "
                                name="totalLimbahNoninfekius"
                                class="form-control form-medium"
                              >
                            </div>
                            <div class="px-3">
                              -
                            </div>
                            <div class="input-group">
                              <div
                                class="icon-group"
                                style="margin-right: 50px"
                              >
                                Tanggal izin
                              </div>
                              <v-menu
                                v-model="calendar2"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                :disabled="
                                  alur.spesifikasi_pengolahan_limbah[
                                    'apakah_terdapat_alat_pengolah_limbah_autoclave_microwave'
                                  ].dataPenyusun_desc != 1
                                "
                                min-width="290px"
                              >
                                <template #activator="{ on, attrs }">
                                  <input
                                    v-model="
                                      alur.spesifikasi_pengolahan_limbah[
                                        'tangggal_izin_autoclave_microwave'
                                      ].dataPenyusun_desc
                                    "
                                    type="text"
                                    placeholder="mm/dd/yyyy"
                                    readonly
                                    v-bind="attrs"
                                    class="form-control form-medium"
                                    v-on="on"
                                  >
                                </template>
                                <v-date-picker
                                  v-model="
                                    alur.spesifikasi_pengolahan_limbah[
                                      'tangggal_izin_autoclave_microwave'
                                    ].dataPenyusun_desc
                                  "
                                  @input="calendar2 = false"
                                />
                              </v-menu>
                              <v-icon class="ml-2">
                                mdi-calendar
                              </v-icon>
                            </div>
                          </div>
                        </div>
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>Apakah Fasyankes melakukan kerja sama dengan pengolah limbah medis berizin ?</p>
                          </div>

                          <v-radio-group
                            v-model="
                              alur.spesifikasi_pengolahan_limbah[
                                'bekerja_sama_dengan_pengolah_limbah_medis'
                              ].dataPenyusun_desc
                            "
                            row
                            dense
                            :disabled="
                              alur.spesifikasi_pengolahan_limbah[
                                'apakah_ingin_menggunakan_data_sebelumnya_'
                              ].dataPenyusun_desc != 2
                            "
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                        <div class="added-form">
                          <div class="d-flex align-center per-form-added">
                            <div class="input-group">
                              <input
                                id="totalLimbahNoninfekius"
                                v-model="
                                  alur.spesifikasi_pengolahan_limbah[
                                    'nama_perusahaan_pengolah_limbah_sesuai_dengan_pks'
                                  ].dataPenyusun_desc
                                "
                                :disabled="
                                  alur.spesifikasi_pengolahan_limbah[
                                    'bekerja_sama_dengan_pengolah_limbah_medis'
                                  ].dataPenyusun_desc != 1
                                "
                                placeholder="Nama Perusahaan pengolah limbah sesuai dengan PKS"
                                name="totalLimbahNoninfekius"
                                class="form-control long_input"
                              >
                            </div>
                            <div class="px-3" />
                            <div class="input-group">
                              <div class="icon-group">
                                No
                              </div>
                              <input
                                id="totalLimbahNoninfekius"
                                v-model="
                                  alur.spesifikasi_pengolahan_limbah['no_pks']
                                    .dataPenyusun_desc
                                "
                                type="number"
                                :disabled="
                                  alur.spesifikasi_pengolahan_limbah[
                                    'bekerja_sama_dengan_pengolah_limbah_medis'
                                  ].dataPenyusun_desc != 1
                                "
                                name="totalLimbahNoninfekius"
                                class="form-control form-medium"
                              >
                            </div>
                            <div class="px-3">
                              -
                            </div>
                            <div class="input-group">
                              <div
                                class="icon-group"
                                style="margin-right: 50px"
                              >
                                Tanggal PKS
                              </div>
                              <v-menu
                                v-model="calendar3"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                :disabled="
                                  alur.spesifikasi_pengolahan_limbah[
                                    'bekerja_sama_dengan_pengolah_limbah_medis'
                                  ].dataPenyusun_desc != 1
                                "
                                min-width="290px"
                              >
                                <template #activator="{ on, attrs }">
                                  <input
                                    v-model="
                                      alur.spesifikasi_pengolahan_limbah[
                                        'tanggal_pks'
                                      ].dataPenyusun_desc
                                    "
                                    type="text"
                                    placeholder="mm/dd/yyyy"
                                    readonly
                                    v-bind="attrs"
                                    class="form-control form-medium"
                                    v-on="on"
                                  >
                                </template>
                                <v-date-picker
                                  v-model="
                                    alur.spesifikasi_pengolahan_limbah[
                                      'tanggal_pks'
                                    ].dataPenyusun_desc
                                  "
                                  @input="calendar3 = false"
                                />
                              </v-menu>
                              <v-icon class="ml-2">
                                mdi-calendar
                              </v-icon>
                            </div>
                          </div>
                        </div>
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>Apakah limbah medis diolah dengan metode lainnya ?</p>
                          </div>

                          <v-radio-group
                            v-model="
                              alur.spesifikasi_pengolahan_limbah[
                                'diolah_dengan_metode_lainnya'
                              ].dataPenyusun_desc
                            "
                            row
                            dense
                            :disabled="
                              alur.spesifikasi_pengolahan_limbah[
                                'apakah_ingin_menggunakan_data_sebelumnya_'
                              ].dataPenyusun_desc != 2
                            "
                            hide-details
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                      </div>
                      <div
                        v-if="code == '3'"
                        class="d-flex justify-end side_right"
                      >
                        <v-btn
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[11]
                              .status == '1'
                          "
                          dark
                          color="#00b4cc"
                          class="text-capitalize"
                          depressed
                        >
                          Sesuai
                        </v-btn>
                        <v-btn
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[11]
                              .status == '0'
                          "
                          dark
                          color="#28577A"
                          class="text-capitalize"
                          depressed
                        >
                          Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                  </div>
                  <!-- Soal 10 -->
                  <div
                    v-if="
                      alur.spesifikasi_pengolahan_limbah[
                        'diolah_dengan_metode_lainnya'
                      ].dataPenyusun_desc == 1
                    "
                    class="border-soal"
                  >
                    <div class="d-flex justify-between mb-4">
                      <div class="title-soal-form d-flex align-center">
                        <h6>Pengolahan limbah dengan metode lainnya</h6>
                      </div>
                      <div class="mt-3" v-if="dataConfirm">
                        <v-btn
                          depressed
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[12]
                              .status == '1'
                          "
                          @click="
                            dataConfirm.tab3.verification_kode_indikator_waste_data[12].status =
                              '0'
                          "
                          color="grey lighten-2"
                          dark
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                        <v-btn
                          depressed
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[12]
                              .status == '0'
                          "
                          @click="
                            dataConfirm.tab3.verification_kode_indikator_waste_data[12].status =
                              '1'
                          "
                          color="primary"
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                    
                    <div class="d-flex">
                      <div :class="code == '3' ? 'side_left' : 'full_width'">
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>Disinfeksi untuk limbah infeksius padat</p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur.pengolahan_limbah_metode_lainnya[
                                'disinfeksi_untuk_limbah_infeksius_padat'
                              ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            :disabled="
                              alur.spesifikasi_pengolahan_limbah[
                                'apakah_ingin_menggunakan_data_sebelumnya_'
                              ].dataPenyusun_desc != 2
                            "
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>
                              Inertifikasi / solidifikasi untuk limbah farmasi
                              dan kimia padat
                            </p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur.pengolahan_limbah_metode_lainnya[
                                'inertifikasi___solidifikasi_untuk_limbah_farmasi_dan_kimia_padat'
                              ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            :disabled="
                              alur.spesifikasi_pengolahan_limbah[
                                'apakah_ingin_menggunakan_data_sebelumnya_'
                              ].dataPenyusun_desc != 2
                            "
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>Enkapsulasi untuk limbah tajam</p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur.pengolahan_limbah_metode_lainnya[
                                'enkapsulasi_untuk_limbah_tajam'
                              ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            :disabled="
                              alur.spesifikasi_pengolahan_limbah[
                                'apakah_ingin_menggunakan_data_sebelumnya_'
                              ].dataPenyusun_desc != 2
                            "
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                        <div class="soal-form bors-sol mt-3">
                          <div class="questions">
                            <p>Penguburan limbah patologi</p>
                          </div>
                          <v-radio-group
                            v-model="
                              alur.pengolahan_limbah_metode_lainnya[
                                'penguburan_limbah_patologi'
                              ].dataPenyusun_desc
                            "
                            row
                            dense
                            hide-details
                            :disabled="
                              alur.spesifikasi_pengolahan_limbah[
                                'apakah_ingin_menggunakan_data_sebelumnya_'
                              ].dataPenyusun_desc != 2
                            "
                            class="ma-0"
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            />
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            />
                          </v-radio-group>
                        </div>
                      </div>
                      <div
                        v-if="code == '3'"
                        class="d-flex justify-end side_right"
                      >
                        <v-btn
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[12]
                              .status == '1'
                          "
                          dark
                          color="#00b4cc"
                          class="text-capitalize"
                          depressed
                        >
                          Sesuai
                        </v-btn>
                        <v-btn
                          v-if="
                            dataConfirm.tab3
                              .verification_kode_indikator_waste_data[12]
                              .status == '0'
                          "
                          dark
                          color="#28577A"
                          class="text-capitalize"
                          depressed
                        >
                          Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </v-card>
              </v-tab-item>

              <!-- Tab 4 -->
              <v-tab-item value="tabs4">
                <v-card
                  v-if="limbah_cair"
                  class="mh-300 custom_card pa-5"
                >
                  <!-- Soal 1 -->
                  <div class="border-soal">
                    <div class="d-flex justify-between mb-4">
                      <div class="title-soal-form d-flex align-center">
                        <h6>Apakah terdapat instalasi pengolahan air limbah (IPAL)</h6>
                      </div>
                      <div class="mt-3" v-if="dataConfirm">
                        <v-btn
                          depressed
                          v-if="
                            dataConfirm.tab4
                              .verification_kode_indikator_waste_data[2]
                              .status == '1'
                          "
                          @click="
                            dataConfirm.tab4.verification_kode_indikator_waste_data[2].status =
                              '0'
                          "
                          color="grey lighten-2"
                          dark
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                        <v-btn
                          depressed
                          v-if="
                            dataConfirm.tab4
                              .verification_kode_indikator_waste_data[2]
                              .status == '0'
                          "
                          @click="
                            dataConfirm.tab4.verification_kode_indikator_waste_data[2].status =
                              '1'
                          "
                          color="primary"
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div
                        :class="
                          code == '3'
                            ? 'added-form  side_left'
                            : 'added-form  full_width'
                        "
                      >
                        <div class="soal-form bors-sol">
                          <div class="questions">
                            <p>
                              Apakah terdapat Instalasi pengelolaan air limbah
                              (IPAL)
                            </p>
                          </div>
                          <div class="d-flex align-center">
                            <v-radio-group
                              v-model="
                                limbah_cair
                                  .instalasi_pengelolaan_air_limbah__ipal_[
                                    'ketersediaan_instalasi_pengeolaan_air_limbah__ipal_'
                                  ].dataPenyusun_desc
                              "
                              row
                              dense
                              hide-details
                              class="ma-0"
                            >
                              <v-radio
                                color="#00ccb6"
                                label="Ya"
                                value="1"
                              />
                              <v-radio
                                color="#00ccb6"
                                label="Tidak"
                                value="2"
                                @click="clearPengolahanAirLimbahData()"
                              />
                            </v-radio-group>
                          </div>
                        </div>
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasKesehatanLingkungan"
                          >
                            Jumlah kapasitas IPAL
                          </label>
                          <div class="input-group">
                            <input
                              id="jumlahPetugasKesehatanLingkungan"
                              v-model="
                                limbah_cair
                                  .instalasi_pengelolaan_air_limbah__ipal_[
                                    'jumlah_kapasitas_ipal'
                                  ].dataPenyusun_desc
                              "
                              type="number"
                              :disabled="
                                limbah_cair
                                  .instalasi_pengelolaan_air_limbah__ipal_[
                                    'ketersediaan_instalasi_pengeolaan_air_limbah__ipal_'
                                  ].dataPenyusun_desc == null || limbah_cair
                                    .instalasi_pengelolaan_air_limbah__ipal_[
                                      'ketersediaan_instalasi_pengeolaan_air_limbah__ipal_'
                                    ].dataPenyusun_desc == 2"
                              name="jumlahPetugasKesehatanLingkungan"
                              class="form-control form-medium"
                            >
                            <div class="icon-group">
                              m<sup>3</sup>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="code == '3'"
                        class="d-flex justify-end side_right"
                      >
                        <v-btn
                          v-if="
                            dataConfirm.tab4
                              .verification_kode_indikator_waste_data[2]
                              .status == '1'
                          "
                          dark
                          color="#00b4cc"
                          class="text-capitalize"
                          depressed
                        >
                          Sesuai
                        </v-btn>
                        <v-btn
                          v-if="
                            dataConfirm.tab4
                              .verification_kode_indikator_waste_data[2]
                              .status == '0'
                          "
                          dark
                          color="#28577A"
                          class="text-capitalize"
                          depressed
                        >
                          Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                  </div>

                  <!-- Soal 2 -->
                  <div class="border-soal">
                    <div class="d-flex justify-between">
                      <div class="title-soal-form d-flex align-center">
                        <h6>Debit</h6>
                      </div>
                      <div class="mt-3" v-if="dataConfirm">
                        <v-btn
                          depressed
                          v-if="
                            dataConfirm.tab4
                              .verification_kode_indikator_waste_data[0]
                              .status == '1'
                          "
                          @click="
                            dataConfirm.tab4.verification_kode_indikator_waste_data[0].status =
                              '0'
                          "
                          color="grey lighten-2"
                          dark
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                        <v-btn
                          depressed
                          v-if="
                            dataConfirm.tab4
                              .verification_kode_indikator_waste_data[0]
                              .status == '0'
                          "
                          @click="
                            dataConfirm.tab4.verification_kode_indikator_waste_data[0].status =
                              '1'
                          "
                          color="primary"
                        >
                          <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                    
                    <div class="d-flex">
                      <div
                        :class="
                          code == '3'
                            ? 'added-form  side_left'
                            : 'added-form  full_width'
                        "
                      >
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasKesehatanLingkungan"
                          >
                            Berapa rata-rata debit air masuk ke IPAL</label>
                          <div class="input-group">
                            <input
                              id="jumlahPetugasKesehatanLingkungan"
                              v-model="
                                limbah_cair.debit[
                                  'rata_rata_debit_air_masuk_ipal'
                                ].dataPenyusun_desc
                              "
                              :disabled="
                                limbah_cair
                                  .instalasi_pengelolaan_air_limbah__ipal_[
                                    'ketersediaan_instalasi_pengeolaan_air_limbah__ipal_'
                                  ].dataPenyusun_desc == null || limbah_cair
                                    .instalasi_pengelolaan_air_limbah__ipal_[
                                      'ketersediaan_instalasi_pengeolaan_air_limbah__ipal_'
                                    ].dataPenyusun_desc == 2
                              "
                              type="number"
                              name="jumlahPetugasKesehatanLingkungan"
                              class="form-control form-medium"
                            >
                            <div class="icon-group">
                              Liter/hari
                            </div>
                          </div>
                        </div>
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasKesehatanLingkungan"
                          >
                            34.Berapa rata-rata debit air keluar dari IPAL</label>
                          <div class="input-group">
                            <input
                              id="jumlahPetugasKesehatanLingkungan"
                              v-model="
                                limbah_cair.debit[
                                  'rata_rata_debit_air_keluar_ipal'
                                ].dataPenyusun_desc
                              "
                              :disabled="
                                limbah_cair
                                  .instalasi_pengelolaan_air_limbah__ipal_[
                                    'ketersediaan_instalasi_pengeolaan_air_limbah__ipal_'
                                  ].dataPenyusun_desc == null || limbah_cair
                                    .instalasi_pengelolaan_air_limbah__ipal_[
                                      'ketersediaan_instalasi_pengeolaan_air_limbah__ipal_'
                                    ].dataPenyusun_desc == 2
                              "
                              type="number"
                              name="jumlahPetugasKesehatanLingkungan"
                              class="form-control form-medium"
                            >
                            <div class="icon-group">
                              Liter/hari
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="code == '3'"
                        class="d-flex justify-end side_right"
                      >
                        <v-btn
                          v-if="
                            dataConfirm.tab4
                              .verification_kode_indikator_waste_data[0]
                              .status == '1'
                          "
                          dark
                          color="#00b4cc"
                          class="text-capitalize"
                          depressed
                        >
                          Sesuai
                        </v-btn>
                        <v-btn
                          v-if="
                            dataConfirm.tab4
                              .verification_kode_indikator_waste_data[0]
                              .status == '0'
                          "
                          dark
                          color="#28577A"
                          class="text-capitalize"
                          depressed
                        >
                          Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                  </div>

                  <!-- Soal 3 -->
                  <div class="border-soal">
                      <div class="d-flex justify-between">
                        <div class="title-soal-form d-flex align-center">
                          <h6>Kualitas air Limbah</h6>
                        </div>
                        <div class="mt-3" v-if="dataConfirm">
                          <v-btn
                            depressed
                            v-if="
                              dataConfirm.tab4
                                .verification_kode_indikator_waste_data[1]
                                .status == '1'
                            "
                            @click="
                              dataConfirm.tab4.verification_kode_indikator_waste_data[1].status =
                                '0'
                            "
                            color="grey lighten-2"
                            dark
                          >
                            <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                          </v-btn>
                          <v-btn
                            depressed
                            v-if="
                              dataConfirm.tab4
                                .verification_kode_indikator_waste_data[1]
                                .status == '0'
                            "
                            @click="
                              dataConfirm.tab4.verification_kode_indikator_waste_data[1].status =
                                '1'
                            "
                            color="primary"
                          >
                            <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                          </v-btn>
                        </div>
                      </div>
                    
                    <div class="d-flex">
                      <div
                        :class="
                          code == '3'
                            ? 'added-form  side_left'
                            : 'added-form  full_width'
                        "
                      >
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasKesehatanLingkungan"
                          >
                            Berapa rata-rata pH harian air limbah ?
                          </label>
                          <div class="input-group">
                            <input
                              id="jumlahPetugasKesehatanLingkungan"
                              v-model="
                                limbah_cair.kualitas_air_limbah[
                                  'rata_rata_ph_harian'
                                ].dataPenyusun_desc
                              "
                              :disabled="
                                limbah_cair
                                  .instalasi_pengelolaan_air_limbah__ipal_[
                                    'ketersediaan_instalasi_pengeolaan_air_limbah__ipal_'
                                  ].dataPenyusun_desc == null || limbah_cair
                                    .instalasi_pengelolaan_air_limbah__ipal_[
                                      'ketersediaan_instalasi_pengeolaan_air_limbah__ipal_'
                                    ].dataPenyusun_desc == 2
                              "
                              type="number"
                              name="jumlahPetugasKesehatanLingkungan"
                              class="form-control form-medium"
                            >
                            <div class="icon-group">
                              1-14
                            </div>
                          </div>
                        </div>
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasKesehatanLingkungan"
                          >
                            Berapa rata-rata suhu harian air limbah ?</label>
                          <div class="input-group">
                            <input
                              id="jumlahPetugasKesehatanLingkungan"
                              v-model="
                                limbah_cair.kualitas_air_limbah[
                                  'rata_rata_suhu_harian'
                                ].dataPenyusun_desc
                              "
                              :disabled="
                                limbah_cair
                                  .instalasi_pengelolaan_air_limbah__ipal_[
                                    'ketersediaan_instalasi_pengeolaan_air_limbah__ipal_'
                                  ].dataPenyusun_desc == null || limbah_cair
                                    .instalasi_pengelolaan_air_limbah__ipal_[
                                      'ketersediaan_instalasi_pengeolaan_air_limbah__ipal_'
                                    ].dataPenyusun_desc == 2
                              "
                              type="number"
                              name="jumlahPetugasKesehatanLingkungan"
                              class="form-control form-medium"
                            >
                            <div class="icon-group">
                              ℃
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="code == '3'"
                        class="d-flex justify-end side_right"
                      >
                        <v-btn
                          v-if="
                            dataConfirm.tab4
                              .verification_kode_indikator_waste_data[1]
                              .status == '1'
                          "
                          dark
                          color="#00b4cc"
                          class="text-capitalize"
                          depressed
                        >
                          Sesuai
                        </v-btn>
                        <v-btn
                          v-if="
                            dataConfirm.tab4
                              .verification_kode_indikator_waste_data[1]
                              .status == '0'
                          "
                          dark
                          color="#28577A"
                          class="text-capitalize"
                          depressed
                        >
                          Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                  </div>

                  <!-- Soal 4 -->
                  <div class="border-soal">
                    <div class="d-flex justify-between mb-4">
                        <div class="title-soal-form d-flex align-center">
                          <h6>Izin pembuangan limbah cair (IPLC)</h6>
                        </div>
                        <div class="mt-3" v-if="dataConfirm">
                          <v-btn
                            depressed
                            v-if="
                              dataConfirm.tab4
                                .verification_kode_indikator_waste_data[3]
                                .status == '1'
                            "
                            @click="
                              dataConfirm.tab4.verification_kode_indikator_waste_data[3].status =
                                '0'
                            "
                            color="grey lighten-2"
                            dark
                          >
                            <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                          </v-btn>
                          <v-btn
                            depressed
                            v-if="
                              dataConfirm.tab4
                                .verification_kode_indikator_waste_data[3]
                                .status == '0'
                            "
                            @click="
                              dataConfirm.tab4.verification_kode_indikator_waste_data[3].status =
                                '1'
                            "
                            color="primary"
                          >
                            <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                          </v-btn>
                        </div>
                    </div>
                    
                    <div class="d-flex">
                      <div
                        :class="
                          code == '3'
                            ? 'added-form  side_left'
                            : 'added-form  full_width'
                        "
                      >
                        <div class="soal-form bors-sol">
                          <div class="questions">
                            <p>
                              Apakah Fasyankes memiliki izin pembuangan limbah cair (IPLC) atau persetujuan teknis IPAL ?
                            </p>
                          </div>
                          <div class="d-flex align-center">
                            <v-radio-group
                              v-model="
                                limbah_cair
                                  .izin_pembuangan_limbah_cair__iplc_[
                                    'apakah_terdapat_izin_pembuangan_limbah_cair__iplc_'
                                  ].dataPenyusun_desc
                              "
                              :disabled="
                                limbah_cair
                                  .instalasi_pengelolaan_air_limbah__ipal_[
                                    'ketersediaan_instalasi_pengeolaan_air_limbah__ipal_'
                                  ].dataPenyusun_desc == null || limbah_cair
                                    .instalasi_pengelolaan_air_limbah__ipal_[
                                      'ketersediaan_instalasi_pengeolaan_air_limbah__ipal_'
                                    ].dataPenyusun_desc == 2
                              "
                              row
                              dense
                              hide-details
                              class="ma-0"
                            >
                              <v-radio
                                color="#00ccb6"
                                label="Ya"
                                value="1"
                              />
                              <v-radio
                                color="#00ccb6"
                                label="Tidak"
                                value="2"
                              />
                            </v-radio-group>
                          </div>
                        </div>
                        <div class="added-form">
                          <div class="d-flex align-center per-form-added">
                            <div class="input-group">
                              <div
                                class="icon-group"
                                style="margin-right: 50px"
                              >
                                Tanggal izin
                              </div>
                              <v-menu
                                v-model="calendar4"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                :disabled="
                                  limbah_cair
                                    .izin_pembuangan_limbah_cair__iplc_[
                                      'apakah_terdapat_izin_pembuangan_limbah_cair__iplc_'
                                    ].dataPenyusun_desc != 1
                                "
                                min-width="290px"
                              >
                                <template #activator="{ on, attrs }">
                                  <input
                                    v-model="
                                      limbah_cair
                                        .izin_pembuangan_limbah_cair__iplc_[
                                          'tanggal_izin_iplc'
                                        ].dataPenyusun_desc
                                    "
                                    type="text"
                                    placeholder="mm/dd/yyyy"
                                    readonly
                                    v-bind="attrs"
                                    class="form-control form-medium"
                                    v-on="on"
                                  >
                                </template>
                                <v-date-picker
                                  v-model="
                                    limbah_cair
                                      .izin_pembuangan_limbah_cair__iplc_[
                                        'tanggal_izin_iplc'
                                      ].dataPenyusun_desc
                                  "
                                  @input="calendar4 = false"
                                />
                              </v-menu>
                              <v-icon class="ml-2">
                                mdi-calendar
                              </v-icon>
                            </div>
                            <div class="px-3">
                              -
                            </div>

                            <div class="input-group">
                              <div class="icon-group">
                                No
                              </div>
                              <input
                                id="totalLimbahNoninfekius"
                                v-model="
                                  limbah_cair
                                    .izin_pembuangan_limbah_cair__iplc_[
                                      'no_iplc'
                                    ].dataPenyusun_desc
                                "
                                type="number"
                                :disabled="
                                  limbah_cair
                                    .izin_pembuangan_limbah_cair__iplc_[
                                      'apakah_terdapat_izin_pembuangan_limbah_cair__iplc_'
                                    ].dataPenyusun_desc != 1
                                "
                                name="totalLimbahNoninfekius"
                                class="form-control form-medium"
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="code == '3'"
                        class="d-flex justify-end side_right"
                      >
                        <v-btn
                          v-if="
                            dataConfirm.tab4
                              .verification_kode_indikator_waste_data[3]
                              .status == '1'
                          "
                          dark
                          color="#00b4cc"
                          class="text-capitalize"
                          depressed
                        >
                          Sesuai
                        </v-btn>
                        <v-btn
                          v-if="
                            dataConfirm.tab4
                              .verification_kode_indikator_waste_data[3]
                              .status == '0'
                          "
                          dark
                          color="#28577A"
                          class="text-capitalize"
                          depressed
                        >
                          Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                  </div>

                  <!-- Soal 5 -->
                  <div class="border-soal">
                    <div class="d-flex justify-between mb-4">
                        <div class="title-soal-form d-flex align-center">
                          <h6>Baku mutu limbah cair</h6>
                        </div>
                        <div class="mt-3" v-if="dataConfirm">
                          <v-btn
                            depressed
                            v-if="
                              dataConfirm.tab4
                                .verification_kode_indikator_waste_data[4]
                                .status == '1'
                            "
                            @click="
                              dataConfirm.tab4.verification_kode_indikator_waste_data[4].status =
                                '0'
                            "
                            color="grey lighten-2"
                            dark
                          >
                            <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                          </v-btn>
                          <v-btn
                            depressed
                            v-if="
                              dataConfirm.tab4
                                .verification_kode_indikator_waste_data[4]
                                .status == '0'
                            "
                            @click="
                              dataConfirm.tab4.verification_kode_indikator_waste_data[4].status =
                                '1'
                            "
                            color="primary"
                          >
                            <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                          </v-btn>
                        </div>
                    </div>
                    <div class="d-flex">
                      <div
                        :class="
                          code == '3'
                            ? 'added-form  side_left'
                            : 'added-form  full_width'
                        "
                      >
                        <div class="soal-form bors-sol">
                          <div class="questions">
                            <p>Apakah hasil uji baku mutu limbah cair sesuai dengan standar ?</p>
                          </div>
                          <div class="d-flex align-center">
                            <v-radio-group
                              v-model="
                                limbah_cair.baku_mutu_limbah_cair[
                                  'apakah_baku_mutu_limbah_cair_sesuai'
                                ].dataPenyusun_desc
                              "
                              :disabled="
                                limbah_cair
                                  .instalasi_pengelolaan_air_limbah__ipal_[
                                    'ketersediaan_instalasi_pengeolaan_air_limbah__ipal_'
                                  ].dataPenyusun_desc == null || limbah_cair
                                    .instalasi_pengelolaan_air_limbah__ipal_[
                                      'ketersediaan_instalasi_pengeolaan_air_limbah__ipal_'
                                    ].dataPenyusun_desc == 2
                              "
                              row
                              dense
                              hide-details
                              class="ma-0"
                            >
                              <v-radio
                                color="#00ccb6"
                                label="Ya"
                                value="1"
                              />
                              <v-radio
                                color="#00ccb6"
                                label="Tidak"
                                value="2"
                              />
                            </v-radio-group>
                          </div>
                        </div>
                        <div class="added-form">
                          <div class="d-flex align-center per-form-added">
                            <div class="input-group">
                              <div
                                class="icon-group"
                                style="margin-right: 50px"
                              >
                                Tanggal izin
                              </div>
                              <v-menu
                                v-model="calendar5"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                :disabled="
                                  limbah_cair.baku_mutu_limbah_cair[
                                    'apakah_baku_mutu_limbah_cair_sesuai'
                                  ].dataPenyusun_desc != 1
                                "
                                min-width="290px"
                              >
                                <template #activator="{ on, attrs }">
                                  <input
                                    v-model="
                                      limbah_cair.baku_mutu_limbah_cair[
                                        'tanggal_mutu_limbah_cair'
                                      ].dataPenyusun_desc
                                    "
                                    type="text"
                                    placeholder="mm/dd/yyyy"
                                    readonly
                                    v-bind="attrs"
                                    class="form-control form-medium"
                                    v-on="on"
                                  >
                                </template>
                                <v-date-picker
                                  v-model="
                                    limbah_cair.baku_mutu_limbah_cair[
                                      'tanggal_mutu_limbah_cair'
                                    ].dataPenyusun_desc
                                  "
                                  @input="calendar5 = false"
                                />
                              </v-menu>
                              <v-icon class="ml-2">
                                mdi-calendar
                              </v-icon>
                            </div>
                            <div class="px-3">
                              -
                            </div>
                            <div class="input-group">
                              <div class="icon-group">
                                No
                              </div>
                              <input
                                id="totalLimbahNoninfekius"
                                v-model="
                                  limbah_cair.baku_mutu_limbah_cair[
                                    'no_mutu_limbah_cair'
                                  ].dataPenyusun_desc
                                "
                                type="number"
                                :disabled="
                                  limbah_cair.baku_mutu_limbah_cair[
                                    'apakah_baku_mutu_limbah_cair_sesuai'
                                  ].dataPenyusun_desc != 1
                                "
                                name="totalLimbahNoninfekius"
                                class="form-control form-medium"
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="code == '3'"
                        class="d-flex justify-end side_right"
                      >
                        <v-btn
                          v-if="
                            dataConfirm.tab4
                              .verification_kode_indikator_waste_data[4]
                              .status == '1'
                          "
                          dark
                          color="#00b4cc"
                          class="text-capitalize"
                          depressed
                        >
                          Sesuai
                        </v-btn>
                        <v-btn
                          v-if="
                            dataConfirm.tab4
                              .verification_kode_indikator_waste_data[4]
                              .status == '0'
                          "
                          dark
                          color="#28577A"
                          class="text-capitalize"
                          depressed
                        >
                          Tidak Sesuai
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </div>
        <!-- NOTES -->
        <div
          v-if="dataConfirm"
          class="d-flex justify-center"
        >
          <!-- note 1 -->
          <v-card
            v-if="tabs == 'tabs1'"
            flat
            class="rounded-max pa-5 mt-5"
            width="80%"
          >
            <v-row>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Pemilahan
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab1.verification_kode_indikator_waste_data[0]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Pemilahan
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab1.verification_kode_indikator_waste_data[1]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Pemilahan
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab1.verification_kode_indikator_waste_data[2]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
            </v-row>
            <div class="mt-5">
              <v-textarea
                v-model="dataConfirm.tab1.notes"
                background-color="#F2F2F2"
                flat
                placeholder="catatan"
                solo
              />
            </div>
          </v-card>

          <!-- note 2 -->
          <v-card
            v-if="tabs == 'tabs2'"
            flat
            class="rounded-max pa-5 mt-5"
            width="80%"
          >
            <v-row>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Unit Khusus / Organisasi Kesehatan Lingkungan
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab2.verification_kode_indikator_waste_data[0]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Petugas Kesehatan Lingkungan
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab2.verification_kode_indikator_waste_data[1]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Petugas Pengelola Limbah B3
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab2.verification_kode_indikator_waste_data[2]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Alokasi Dana / Anggaran (Tahunan)
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab2.verification_kode_indikator_waste_data[3]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Dokumen Izin Lingkungann
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab2.verification_kode_indikator_waste_data[4]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Kebijakan Tertulis (SE/SK) Mengenai Pengelolaan Limbah
                    Medis/B3
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab2.verification_kode_indikator_waste_data[5]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Prosedur Pengelolaan Limbah (SOP)
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab2.verification_kode_indikator_waste_data[6]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Penyediaan Wadah
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab2.verification_kode_indikator_waste_data[7]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Penyediaan Alat Angkut
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab2.verification_kode_indikator_waste_data[8]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Penyediaan Alat Pelindung Diri (APD)
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab2.verification_kode_indikator_waste_data[9]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Timbangan Medis
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab2.verification_kode_indikator_waste_data[10]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Pencatatan Limbah Medis (Log Book)
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab2.verification_kode_indikator_waste_data[11]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Cold storage / Freezer Khusus Limbah Medis
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab2.verification_kode_indikator_waste_data[12]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Kapasitas Cold Storage / Freezer
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab2.verification_kode_indikator_waste_data[13]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
            </v-row>
            <div class="mt-5">
              <v-textarea
                v-model="dataConfirm.tab2.notes"
                background-color="#F2F2F2"
                flat
                placeholder="catatan"
                solo
              />
            </div>
          </v-card>

          <!-- note 3 -->
          <v-card
            v-if="tabs == 'tabs3'"
            flat
            class="rounded-max pa-5 mt-5"
            width="80%"
          >
            <v-row>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Pemilahan Limbah padat domestik
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab3.verification_kode_indikator_waste_data[0]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Pemilahan limbah medis infeksius
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab3.verification_kode_indikator_waste_data[1]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Pemilahan Limbah padat medis non-infeksius
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab3.verification_kode_indikator_waste_data[2]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Jenis pewadahan Limbah Domestik
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >{{
                    dataConfirm.tab3.verification_kode_indikator_waste_data[3]
                      .status == "1"
                      ? "mdi-checkbox-outline"
                      : "mdi-close-box"
                  }}</v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Jenis Pewadahan Limbah Medis Infeksius
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >{{
                    dataConfirm.tab3.verification_kode_indikator_waste_data[4]
                      .status == "1"
                      ? "mdi-checkbox-outline"
                      : "mdi-close-box"
                  }}</v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Jenis Pewadahan Limbah Medis Non-Infeksius
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >{{
                    dataConfirm.tab3.verification_kode_indikator_waste_data[5]
                      .status == "1"
                      ? "mdi-checkbox-outline"
                      : "mdi-close-box"
                  }}</v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Spesifikasi pewadahan plastik
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab3.verification_kode_indikator_waste_data[6]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Spesifikasi Pengangkutan ke Tempat Penyimpanan
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab3.verification_kode_indikator_waste_data[7]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Ketersediaan Ruang Penyimpanan
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab3.verification_kode_indikator_waste_data[8]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Ketersediaan pengangkutan limbah ke tempat pengolah
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab3.verification_kode_indikator_waste_data[9]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">Ketersediaan Alat Pengolah Limbah Di Fasyankes</p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab3.verification_kode_indikator_waste_data[10]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">Spesifikasi pengolahan limbah</p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab3.verification_kode_indikator_waste_data[11]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Pengolahan limbah metode lainnya
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab3.verification_kode_indikator_waste_data[12]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
            </v-row>
            <div class="mt-5">
              <v-textarea
                v-model="dataConfirm.tab3.notes"
                background-color="#F2F2F2"
                flat
                placeholder="catatan"
                solo
              />
            </div>
          </v-card>

          <!-- note 4 -->
          <v-card
            v-if="tabs == 'tabs4'"
            flat
            class="rounded-max pa-5 mt-5"
            width="80%"
          >
            <v-row>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Debit
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab4.verification_kode_indikator_waste_data[0]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Kualitas Air Limbah
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab4.verification_kode_indikator_waste_data[1]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Instalasi pengelolaan air limbah (IPAL)
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab4.verification_kode_indikator_waste_data[2]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                    Izin pembuangan limbah cair (IPLC)
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab4.verification_kode_indikator_waste_data[3]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  class="pa-3 d-flex justify-space-between align-center"
                  flat
                  color="#F2F2F2"
                >
                  <p class="ma-0 color_txt small_txt">
                   Baku mutu limbah cair
                  </p>
                  <v-icon
                    color="#00b4cc"
                    class="mr-2"
                  >
                    {{
                      dataConfirm.tab4.verification_kode_indikator_waste_data[4]
                        .status == "1"
                        ? "mdi-checkbox-outline"
                        : "mdi-close-box"
                    }}
                  </v-icon>
                </v-card>
              </v-col>
            </v-row>
            <div class="mt-5">
              <v-textarea
                v-model="dataConfirm.tab4.notes"
                background-color="#F2F2F2"
                flat
                placeholder="catatan"
                solo
              />
            </div>
          </v-card>
        </div>
        <div
          v-if="tabs == 'tabs4'"
          class="d-flex justify-center"
        >
          <v-card
            flat
            class="rounded-max pa-6 d-flex justify-center mt-5"
            width="80%"
          >
            <v-btn
              class="text-capitalize px-10 py-5"
              dark
              color="#00b4cc"
              @click="dialogAlert = true"
            >
              Konfrimasi
            </v-btn>
          </v-card>
        </div>
        <div class="pa-2">
          <alert-confirm
            :dialog-alert="dialogAlert"
            @pick="setupForm"
            @close="dialogAlert = false"
          />
          <v-overlay
            :absolute="true"
            color="white"
            opacity="1"
            :value="loading"
          >
            <v-progress-circular
              indeterminate
              class="mr-2"
              color="#00b4cc"
            />
            <b class="color_default">Loading...</b>
          </v-overlay>
        </div>
        <div class="paginat">
          <div class="button-pag">
            <div>
              <v-btn
                color="white"
                dark
                class="pa-3 custom_shadow rounded-lg text-capitalize"
                @click="tabKembali"
              >
                <v-icon
                  small
                  color="#00b4cc"
                >
                  mdi-arrow-left-circle-outline
                </v-icon>
                <p class="ma-0 color_default ml-2">
                  Kembali
                </p>
              </v-btn>
            </div>
            <div>
              <p class="ma-0 color_default">
                {{ paginNow }} / 4
              </p>
            </div>

            <div>
              <v-btn
                v-if="tabs != 'tabs4'"
                min-width="10px"
                color="#00b4cc"
                dark
                class="pa-2 custom_shadow rounded-lg text-capitalize"
                @click="tabLanjut"
              >
                <p class="ma-0 mr-2">
                  Lanjut
                </p>
                <v-icon small>
                  mdi-arrow-right-circle-outline
                </v-icon>
              </v-btn>

              <v-btn
                v-if="tabs == 'tabs4'"
                min-width="10px"
                color="#00b4cc"
                dark
                class="pa-2 custom_shadow rounded-lg text-capitalize"
              >
                <p class="ma-0 mr-2">
                  Kirim
                </p>
                <v-icon small>
                  mdi-tray-arrow-up
                </v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <!-- </v-container> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import HeaderFormulir from "../../../components/Formulir/HeaderFormulir.vue";
import AlertConfirm from "./alertConfirm.vue";

export default {
  name: "KonfirmasiFasyankes",
  components: { HeaderFormulir, AlertConfirm },
  data() {
    return {
      code: null,
      tidak_sesuai: [1],
      value0: 1,
      loading: false,
      isiFormulir: true,
      tab: 2,
      tabs: "tabs1",
      menu: false,
      role: "",
      users: false,
      menuLimit: false,
      paginNow: 1,
      formulir: {
        bor: 1,
        pemilahanDataLimbahPadat: null,
        pemilihanLimbahDomestik: null,
        limbahOrganik: null,
        limbahAnorganik: null,
        totalLimbahDomestik: null,
        limbahmedis: null,
        noninfeksius: null,
        rataTimbulanLimbahInfeksius: null,
        rataTimbulanLimbahTajam: null,
        rataTimbulanLimbahPatologi: null,
        totalLimbahInfekius: null,
        rataTimbulanLimbahFarmasi: null,
        rataTimbulanLimbahKimia: null,
        rataTimbulanLimbahSitotoksik: null,
        rataTimbulanLimbahRadioAktif: null,
        totalLimbahNoninfekius: null,
        KetersediaanUnit: null,
      },
      id: null,
      dialogSuccess: false,
      dialogAlert: false,
      temporary: true,
      loadTempo: false,
      date: null,
      tobeSent: null,
      calendar1: false,
      calendar2: false,
      calendar3: false,
      calendar4: false,
      calendar5: false,
      dataConfirm: null,
      code: null
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.user.profile,
      data_form: (state) => state.formulir.limbah_fasyankes,
      getBor: (state) => state.getBor,
      reportId: (state) => state.reportId,
    }),
    alur() {
      let data = null;
      if (this.data_form) {
        data = this.data_form.limbah_fasyankes.alur_pengelolaan_limbah_padat;
      }
      return data;
    },
    limbah_padat() {
      let data = null;
      if (this.data_form) {
        data = this.data_form.limbah_fasyankes.data_limbah_padat;
      }
      return data;
    },
    limbah_cair() {
      let data = null;
      if (this.data_form) {
        data = this.data_form.limbah_fasyankes.pengelolaan_limbah_cair;
      }
      return data;
    },
    sumber_daya() {
      let data = null;
      if (this.data_form) {
        data = this.data_form.limbah_fasyankes.sumber_daya_dan_organisasi;
      }
      return data;
    },
  },
  
  mounted() {
    this.id = this.$route.params.id;
    this.getData();
    this.getDataConfrim();
  },
  methods: {
    getDataConfrim() {
      this.loading = true;
      this.$store
        .dispatch("formulir/getDetailConfirm", this.id)
        .then((data) => {
          let obj = {};
          data.verification.forEach((el) => {
            if (el.kode_indikator.kodeIndikator_name == "Data Limbah Padat") {
              if (data.status_code == "0" || data.status_code == "1") {
                el.verification_kode_indikator_waste_data.forEach((i) => {
                  i.status = "1";
                });
              }
              obj["tab1"] = el;
            } else if (
              el.kode_indikator.kodeIndikator_name ==
              "Sumber Daya dan organisasi"
            ) {
              if (data.status_code == "0" || data.status_code == "1") {
                el.verification_kode_indikator_waste_data.forEach((i) => {
                  i.status = "1";
                });
              }
              obj["tab2"] = el;
            } else if (
              el.kode_indikator.kodeIndikator_name ==
              "Alur Pengelolaan limbah padat"
            ) {
              if (data.status_code == "0" || data.status_code == "1") {
                el.verification_kode_indikator_waste_data.forEach((i) => {
                  i.status = "1";
                });
              }
              obj["tab3"] = el;
            } else if (
              el.kode_indikator.kodeIndikator_name == "Pengelolaan Limbah Cair"
            ) {
              if (data.status_code == "0" || data.status_code == "1") {
                el.verification_kode_indikator_waste_data.forEach((i) => {
                  i.status = "1";
                });
              }
              obj["tab4"] = el;
            }
          });
          obj.tab4.verification_kode_indikator_waste_data.forEach((el, index) => {
            console.log(index, el.waste_data.wasteData_name);
            
          });
          this.dataConfirm = obj;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getData() {
      this.loading = true;
      let body = {
        report_id: this.id,
      };
      this.$store
        .dispatch("formulir/getFormulirFasyankes", body)
        .then((data) => {
          this.date = data.data.date;
          this.code = data.data.status_code;
          this.loading = false;
        });
    },

    setupForm(verif_status) {
      this.loading = true;
      let data = {
        report_id: this.id,
        verification_id: this.dataConfirm.tab1.verification_id,
        verification_status: verif_status,
        data: [
          // FORM 1
          {
            kodeIndikator_id: this.dataConfirm.tab1.kodeIndikator_id,
            notes: this.dataConfirm.tab1.notes,
            data: [
              {
                wasteData_id:
                  this.dataConfirm.tab1
                    .verification_kode_indikator_waste_data[0].wasteData_id,
                status:
                  this.dataConfirm.tab1
                    .verification_kode_indikator_waste_data[0].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab1
                    .verification_kode_indikator_waste_data[1].wasteData_id,
                status:
                  this.dataConfirm.tab1
                    .verification_kode_indikator_waste_data[1].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab1
                    .verification_kode_indikator_waste_data[2].wasteData_id,
                status:
                  this.dataConfirm.tab1
                    .verification_kode_indikator_waste_data[2].status,
              },
            ],
          },

          // FORM 2
          {
            kodeIndikator_id: this.dataConfirm.tab2.kodeIndikator_id,
            notes: this.dataConfirm.tab2.notes,
            data: [
              {
                wasteData_id:
                  this.dataConfirm.tab2
                    .verification_kode_indikator_waste_data[0].wasteData_id,
                status:
                  this.dataConfirm.tab2
                    .verification_kode_indikator_waste_data[0].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab2
                    .verification_kode_indikator_waste_data[1].wasteData_id,
                status:
                  this.dataConfirm.tab2
                    .verification_kode_indikator_waste_data[1].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab2
                    .verification_kode_indikator_waste_data[2].wasteData_id,
                status:
                  this.dataConfirm.tab2
                    .verification_kode_indikator_waste_data[2].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab2
                    .verification_kode_indikator_waste_data[3].wasteData_id,
                status:
                  this.dataConfirm.tab2
                    .verification_kode_indikator_waste_data[3].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab2
                    .verification_kode_indikator_waste_data[4].wasteData_id,
                status:
                  this.dataConfirm.tab2
                    .verification_kode_indikator_waste_data[4].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab2
                    .verification_kode_indikator_waste_data[5].wasteData_id,
                status:
                  this.dataConfirm.tab2
                    .verification_kode_indikator_waste_data[5].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab2
                    .verification_kode_indikator_waste_data[6].wasteData_id,
                status:
                  this.dataConfirm.tab2
                    .verification_kode_indikator_waste_data[6].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab2
                    .verification_kode_indikator_waste_data[7].wasteData_id,
                status:
                  this.dataConfirm.tab2
                    .verification_kode_indikator_waste_data[7].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab2
                    .verification_kode_indikator_waste_data[8].wasteData_id,
                status:
                  this.dataConfirm.tab2
                    .verification_kode_indikator_waste_data[8].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab2
                    .verification_kode_indikator_waste_data[9].wasteData_id,
                status:
                  this.dataConfirm.tab2
                    .verification_kode_indikator_waste_data[9].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab2
                    .verification_kode_indikator_waste_data[10].wasteData_id,
                status:
                  this.dataConfirm.tab2
                    .verification_kode_indikator_waste_data[10].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab2
                    .verification_kode_indikator_waste_data[11].wasteData_id,
                status:
                  this.dataConfirm.tab2
                    .verification_kode_indikator_waste_data[11].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab2
                    .verification_kode_indikator_waste_data[12].wasteData_id,
                status:
                  this.dataConfirm.tab2
                    .verification_kode_indikator_waste_data[12].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab2
                    .verification_kode_indikator_waste_data[13].wasteData_id,
                status:
                  this.dataConfirm.tab2
                    .verification_kode_indikator_waste_data[13].status,
              },
            ],
          },

          // FORM 3
          {
            kodeIndikator_id: this.dataConfirm.tab3.kodeIndikator_id,
            notes: this.dataConfirm.tab3.notes,
            data: [
              {
                wasteData_id:
                  this.dataConfirm.tab3
                    .verification_kode_indikator_waste_data[0].wasteData_id,
                status:
                  this.dataConfirm.tab3
                    .verification_kode_indikator_waste_data[0].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab3
                    .verification_kode_indikator_waste_data[1].wasteData_id,
                status:
                  this.dataConfirm.tab3
                    .verification_kode_indikator_waste_data[1].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab3
                    .verification_kode_indikator_waste_data[2].wasteData_id,
                status:
                  this.dataConfirm.tab3
                    .verification_kode_indikator_waste_data[2].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab3
                    .verification_kode_indikator_waste_data[3].wasteData_id,
                status:
                  this.dataConfirm.tab3
                    .verification_kode_indikator_waste_data[3].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab3
                    .verification_kode_indikator_waste_data[4].wasteData_id,
                status:
                  this.dataConfirm.tab3
                    .verification_kode_indikator_waste_data[4].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab3
                    .verification_kode_indikator_waste_data[5].wasteData_id,
                status:
                  this.dataConfirm.tab3
                    .verification_kode_indikator_waste_data[5].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab3
                    .verification_kode_indikator_waste_data[6].wasteData_id,
                status:
                  this.dataConfirm.tab3
                    .verification_kode_indikator_waste_data[6].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab3
                    .verification_kode_indikator_waste_data[7].wasteData_id,
                status:
                  this.dataConfirm.tab3
                    .verification_kode_indikator_waste_data[7].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab3
                    .verification_kode_indikator_waste_data[8].wasteData_id,
                status:
                  this.dataConfirm.tab3
                    .verification_kode_indikator_waste_data[8].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab3
                    .verification_kode_indikator_waste_data[9].wasteData_id,
                status:
                  this.dataConfirm.tab3
                    .verification_kode_indikator_waste_data[9].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab3
                    .verification_kode_indikator_waste_data[10].wasteData_id,
                status:
                  this.dataConfirm.tab3
                    .verification_kode_indikator_waste_data[10].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab3
                    .verification_kode_indikator_waste_data[11].wasteData_id,
                status:
                  this.dataConfirm.tab3
                    .verification_kode_indikator_waste_data[11].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab3
                    .verification_kode_indikator_waste_data[12].wasteData_id,
                status:
                  this.dataConfirm.tab3
                    .verification_kode_indikator_waste_data[12].status,
              },
            ],
          },

          // FORM 4
          {
            kodeIndikator_id: this.dataConfirm.tab4.kodeIndikator_id,
            notes: this.dataConfirm.tab4.notes,
            data: [
              {
                wasteData_id:
                  this.dataConfirm.tab4
                    .verification_kode_indikator_waste_data[0].wasteData_id,
                status:
                  this.dataConfirm.tab4
                    .verification_kode_indikator_waste_data[0].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab4
                    .verification_kode_indikator_waste_data[1].wasteData_id,
                status:
                  this.dataConfirm.tab4
                    .verification_kode_indikator_waste_data[1].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab4
                    .verification_kode_indikator_waste_data[2].wasteData_id,
                status:
                  this.dataConfirm.tab4
                    .verification_kode_indikator_waste_data[2].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab4
                    .verification_kode_indikator_waste_data[3].wasteData_id,
                status:
                  this.dataConfirm.tab4
                    .verification_kode_indikator_waste_data[3].status,
              },
              {
                wasteData_id:
                  this.dataConfirm.tab4
                    .verification_kode_indikator_waste_data[4].wasteData_id,
                status:
                  this.dataConfirm.tab4
                    .verification_kode_indikator_waste_data[4].status,
              },
            ],
          },
        ],
      };
      console.log(data);

      this.$store.dispatch("formulir/confirm", data).then((data) => {
        console.log(data);
        this.loading = false;
      });
    },

    tabLanjut() {
      if (this.tabs == "tabs1") {
        this.tabs = "tabs2";
        this.paginNow++;
      } else if (this.tabs == "tabs2") {
        this.tabs = "tabs3";
        this.paginNow++;
      } else if (this.tabs == "tabs3") {
        this.tabs = "tabs4";
        this.paginNow++;
      }
    },
    tabKembali() {
      if (this.tabs == "tabs4") {
        this.tabs = "tabs3";
        this.paginNow--;
      } else if (this.tabs == "tabs3") {
        this.tabs = "tabs2";
        this.paginNow--;
      } else if (this.tabs == "tabs2") {
        this.tabs = "tabs1";
        this.paginNow--;
      }
    },
  },
};
</script>

<style scoped>
.side_toolbar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 99 !important;
}
.item_toolbar {
  margin: 0 20px;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: rgb(0 180 204 / 18%) 0px 5px 7px !important;
}
.long_input {
  width: 250px;
}
.long_input:placeholder {
  font-size: small;
}
.long_input:focus {
  outline: none;
}
.bors-sol {
  flex-grow: 1;
  margin-right: 20px;
}
.justify-between {
  justify-content: space-between !important;
}
.full_width {
  width: 100%;
}
.side_left {
  width: 83%;
}
.side_right {
  width: 17%;
}
.span-catatan {
  color: #96979e;
}
</style>