<template>
  <v-row justify="start">
    <v-dialog v-model="dialogAlert" persistent max-width="450px">
      <v-card class="rounded-max hide_overflow" flat>
        <div class="closer d-flex justify-end pa-1">
          <v-btn icon small @click="$emit('close')">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </div>
        <div
          class="d-flex flex-column align-center justify-center"
          style="height: 230px"
        >
          <div class="d-flex flex-column">
            <v-btn
              class="text-capitalize pa-7 mb-5"
              @click="pickOpt(2)"
              dark
              color="#00b4cc"
              depressed
              >Tanpa perbaikan</v-btn
            >
            <v-btn
              class="text-capitalize pa-7"
              @click="pickOpt(3)"
              outlined
              color="#00b4cc"
              depressed
              >Perlu perbaikan</v-btn
            >
          </div>
          <div style="height: 20px"></div>
          <v-overlay
            :absolute="true"
            color="white"
            opacity="1"
            :value="loading"
          >
            <v-progress-circular
              indeterminate
              class="mr-2"
              color="#00b4cc"
            ></v-progress-circular>
            <b class="color_default">Loading...</b>
          </v-overlay>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "alertFormulir",
  props: ["dialogAlert"],

  data() {
    return {
      loading: false,
    };
  },
  methods: {
    pickOpt(item) {
      this.$emit("pick", item);
      this.$emit("close");
    },
  },
};
</script>

<style scoped>

.closer {
  width: 100%;
}

.hide_overflow {
  overflow: hidden;
}
.family {
  font-family: Arial, Helvetica, sans-serif;
}
.small-txt {
  font-size: small !important;
}
</style>